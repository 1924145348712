import React from 'react'
import styled from 'styled-components';

const Title = styled.h2`
  text-transform:uppercase;
  padding-left:28px;
  font-size:42px;
  font-family: 'Avenir Next Medium';
  padding-top:40px;
  font-style:italic;
  font-weight:400;
  margin: 10px 0 15px 0;
  line-height: 30px;
   @media(max-height: 1000px){
    font-size:32px;
    padding-top:30px;
  }
`;


const ModalTitle = ({title}) => {
  return <Title>{title}</Title>
}

export default ModalTitle