
import React, { useEffect, useState }  from "react";
import { useGLTF } from "@react-three/drei";
import Sound from './Sound'

function SoundBoundingBoxen(props) {
  const { nodes, materials } = useGLTF("/3D/SoundBoxen.gltf");
  const { userInteracted, soundRefs, setSoundRefs} = props;
  const [ Volume, setVolume ] = useState("0");

  useEffect(()=>{
    
    if(userInteracted) setVolume("175")
  },[userInteracted])

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Wald.geometry}
        material={materials.Unsichtbar}
        position={[0.68, 1.17, -7.69]}
        scale={0.1}
      >
        <Sound index={0} soundRefs={soundRefs} setSoundRefs={setSoundRefs} url="/audios/background/forest_noise.mp3" volume={Volume}/>
      </mesh>
      <mesh
        geometry={nodes.Wald001.geometry}
        material={materials.Unsichtbar}
        position={[-1.24, 1.17, -6.2]}
        scale={0.1}
      >
        <Sound index={1} soundRefs={soundRefs} setSoundRefs={setSoundRefs} url="/audios/background/forest_noise.mp3" volume={Volume}/>
      </mesh>
      <mesh
        geometry={nodes.Wald002.geometry}
        material={materials.Unsichtbar}
        position={[4.26, 1.17, -6.92]}
        scale={0.1}
      >
        <Sound index={2} soundRefs={soundRefs} setSoundRefs={setSoundRefs} url="/audios/background/forest_noise.mp3" volume={Volume}/>
      </mesh>
      <mesh
        geometry={nodes.Wald003.geometry}
        material={materials.Unsichtbar}
        position={[4.59, 1.17, -13.69]}
        scale={0.1}
      >
        <Sound index={3} soundRefs={soundRefs} setSoundRefs={setSoundRefs} url="/audios/background/forest_noise.mp3" volume={Volume}/>
      </mesh>
      <mesh
        geometry={nodes.Wald004.geometry}
        material={materials.Unsichtbar}
        position={[1.17, 1.17, -7.37]}
        scale={0.1}
      >
        <Sound index={4} soundRefs={soundRefs} setSoundRefs={setSoundRefs} url="/audios/background/forest_noise.mp3" volume={Volume}/>
      </mesh>
      <mesh
        geometry={nodes.Wald005.geometry}
        material={materials.Unsichtbar}
        position={[6.83, 1.17, -12.2]}
        scale={0.1}
      >
        <Sound index={5} soundRefs={soundRefs} setSoundRefs={setSoundRefs} url="/audios/background/forest_noise.mp3" volume={Volume}/>
      </mesh>
      <mesh
        geometry={nodes.Wald006.geometry}
        material={materials.Unsichtbar}
        position={[2.23, 1.17, -5.03]}
        scale={0.1}
      >
        <Sound index={6} soundRefs={soundRefs} setSoundRefs={setSoundRefs} url="/audios/background/forest_noise.mp3" volume={Volume}/>
      </mesh>
      <mesh
        geometry={nodes.Wald007.geometry}
        material={materials.Unsichtbar}
        position={[5.71, 1.17, 0.47]}
        scale={0.1}
      >
        <Sound index={7} soundRefs={soundRefs} setSoundRefs={setSoundRefs} url="/audios/background/forest_noise.mp3" volume={Volume}/>
      </mesh>
      <mesh
        geometry={nodes.Wald008.geometry}
        material={materials.Unsichtbar}
        position={[6.1, 1.17, 2.62]}
        scale={0.1}
      >
        <Sound index={8} soundRefs={soundRefs} setSoundRefs={setSoundRefs} url="/audios/background/forest_noise.mp3" volume={Volume}/>
      </mesh>
      <mesh
        geometry={nodes.Wald009.geometry}
        material={materials.Unsichtbar}
        position={[8.57, 1.17, 1.09]}
        scale={0.1}
      >
        <Sound index={9} soundRefs={soundRefs} setSoundRefs={setSoundRefs} url="/audios/background/forest_noise.mp3" volume={Volume}/>
      </mesh>
      <mesh
        geometry={nodes.WellenSound.geometry}
        material={materials.Unsichtbar}
        position={[-7.31, 1.17, -9.78]}
        scale={0.1}
      >
      </mesh>
      <mesh
        geometry={nodes.WellenSound001.geometry}
        material={materials.Unsichtbar}
        position={[-15.7, 1.17, -1.35]}
        scale={0.1}
      >
        <Sound index={10} soundRefs={soundRefs} setSoundRefs={setSoundRefs} url="/audios/background/ocean_waves.mp3" volume={Volume}/>
      </mesh>
      <mesh
        geometry={nodes.WellenSound002.geometry}
        material={materials.Unsichtbar}
        position={[-11.27, 1.17, -3.08]}
        scale={0.1}
      >
      <Sound index={11} soundRefs={soundRefs} setSoundRefs={setSoundRefs} url="/audios/background/ocean_waves.mp3" volume={Volume}/>
      </mesh>
      <mesh
        geometry={nodes.WellenSound003.geometry}
        material={materials.Unsichtbar}
        position={[-14.72, 1.17, -7.54]}
        scale={0.1}
      >
        <Sound index={12} soundRefs={soundRefs} setSoundRefs={setSoundRefs} url="/audios/background/ocean_waves.mp3" volume={Volume}/>
      </mesh>
      <mesh
        geometry={nodes.WellenSound004.geometry}
        material={materials.Unsichtbar}
        position={[-11.22, 1.17, 2.38]}
        scale={0.1}
      >
        <Sound index={13} soundRefs={soundRefs} setSoundRefs={setSoundRefs} url="/audios/background/ocean_waves.mp3" volume={Volume}/>
      </mesh>
      <mesh
        geometry={nodes.WellenSound005.geometry}
        material={materials.Unsichtbar}
        position={[-11.32, 1.17, 7.16]}
        scale={0.1}
      >
        <Sound index={14} soundRefs={soundRefs} setSoundRefs={setSoundRefs} url="/audios/background/ocean_waves.mp3" volume={Volume}/>
      </mesh>
      <mesh
        geometry={nodes.WellenSound006.geometry}
        material={materials.Unsichtbar}
        position={[-11.28, 1.17, 12.13]}
        scale={0.1}
      >
        <Sound index={15} soundRefs={soundRefs} setSoundRefs={setSoundRefs} url="/audios/background/ocean_waves.mp3" volume={Volume}/>
      </mesh>
      <mesh
        geometry={nodes.WellenSound007.geometry}
        material={materials.Unsichtbar}
        position={[-7.48, 1.17, 8.08]}
        scale={0.1}
      >
        <Sound index={16} soundRefs={soundRefs} setSoundRefs={setSoundRefs} url="/audios/background/ocean_waves.mp3" volume={Volume}/>
      </mesh>
      <mesh
        geometry={nodes.WellenSound008.geometry}
        material={materials.Unsichtbar}
        position={[5.29, 1.17, 7.42]}
        scale={0.1}
      >
        <Sound index={17} soundRefs={soundRefs} setSoundRefs={setSoundRefs} url="/audios/background/ocean_waves.mp3" volume={Volume}/>
      </mesh>
      <mesh
        geometry={nodes.WellenSound009.geometry}
        material={materials.Unsichtbar}
        position={[2.81, 1.17, 11.73]}
        scale={0.1}
      >
        <Sound index={18} soundRefs={soundRefs} setSoundRefs={setSoundRefs} url="/audios/background/ocean_waves.mp3" volume={Volume}/>
      </mesh>
      <mesh
        geometry={nodes.WellenSound010.geometry}
        material={materials.Unsichtbar}
        position={[7.12, 1.17, 11.73]}
        scale={0.1}
      >
        <Sound index={19} soundRefs={soundRefs} setSoundRefs={setSoundRefs} url="/audios/background/ocean_waves.mp3" volume={Volume}/>
      </mesh>
      <mesh
        geometry={nodes.WellenSound011.geometry}
        material={materials.Unsichtbar}
        position={[10.74, 1.17, 3.64]}
        scale={0.1}
      >
        <Sound index={20} soundRefs={soundRefs} setSoundRefs={setSoundRefs} url="/audios/background/ocean_waves.mp3" volume={Volume}/>
      </mesh>
      <mesh
        geometry={nodes.WellenSound012.geometry}
        material={materials.Unsichtbar}
        position={[15.42, 1.17, 5.87]}
        scale={0.1}
      >
        <Sound index={21} soundRefs={soundRefs} setSoundRefs={setSoundRefs} url="/audios/background/ocean_waves.mp3" volume={Volume}/>
      </mesh>
      <mesh
        geometry={nodes.WellenSound013.geometry}
        material={materials.Unsichtbar}
        position={[14.32, 1.17, 1]}
        scale={0.1}
      >
        <Sound index={22} soundRefs={soundRefs} setSoundRefs={setSoundRefs} url="/audios/background/ocean_waves.mp3" volume={Volume}/>
      </mesh>
      <mesh
        geometry={nodes.WellenSound014.geometry}
        material={materials.Unsichtbar}
        position={[16.52, 1.17, -1.99]}
        scale={0.1}
      >
        <Sound index={23} soundRefs={soundRefs} setSoundRefs={setSoundRefs} url="/audios/background/ocean_waves.mp3" volume={Volume}/>
      </mesh>
      <mesh
        geometry={nodes.WellenSound015.geometry}
        material={materials.Unsichtbar}
        position={[12.81, 1.17, -4.95]}
        scale={0.1}
      >
        <Sound index={24} soundRefs={soundRefs} setSoundRefs={setSoundRefs} url="/audios/background/ocean_waves.mp3" volume={Volume}/>
      </mesh>
      <mesh
        geometry={nodes.WellenSound016.geometry}
        material={materials.Unsichtbar}
        position={[15.93, 1.17, -7.46]}
        scale={0.1}
      >
        <Sound index={25} soundRefs={soundRefs} setSoundRefs={setSoundRefs} url="/audios/background/ocean_waves.mp3" volume={Volume}/>
      </mesh>
      <mesh
        geometry={nodes.WellenSound017.geometry}
        material={materials.Unsichtbar}
        position={[11.24, 1.17, -9.7]}
        scale={0.1}
      >
        <Sound index={26} soundRefs={soundRefs} setSoundRefs={setSoundRefs} url="/audios/background/ocean_waves.mp3" volume={Volume}/>
      </mesh>
      <mesh
        geometry={nodes.WellenSound018.geometry}
        material={materials.Unsichtbar}
        position={[-8.15, 1.17, -6.23]}
        scale={0.1}
      >
        <Sound index={27} soundRefs={soundRefs} setSoundRefs={setSoundRefs} url="/audios/background/ocean_waves.mp3" volume={Volume}/>
      </mesh>
      <mesh
        geometry={nodes.WieseSound.geometry}
        material={materials.Unsichtbar}
        position={[2.48, 1.17, 1.28]}
        scale={0.1}
      >
        <Sound index={28} soundRefs={soundRefs} setSoundRefs={setSoundRefs} url="/audios/background/gras_noise_1.1.mp3" volume={Volume}/>
      </mesh>
      <mesh
        geometry={nodes.WieseSound001.geometry}
        material={materials.Unsichtbar}
        position={[0.12, 1.17, 0.04]}
        scale={0.1}
      >
        <Sound index={29} soundRefs={soundRefs} setSoundRefs={setSoundRefs} url="/audios/background/gras_noise_1.1.mp3" volume={Volume}/>
      </mesh>
      <mesh
        geometry={nodes.WieseSound002.geometry}
        material={materials.Unsichtbar}
        position={[2.61, 1.17, -1.93]}
        scale={0.1}
      >
        <Sound index={30} soundRefs={soundRefs} setSoundRefs={setSoundRefs} url="/audios/background/gras_noise_1.1.mp3" volume={Volume}/>
      </mesh>
      <mesh
        geometry={nodes.WieseSound003.geometry}
        material={materials.Unsichtbar}
        position={[-2.57, 1.17, -6.34]}
        scale={0.1}
      >
        <Sound index={31} soundRefs={soundRefs} setSoundRefs={setSoundRefs} url="/audios/background/gras_noise_1.1.mp3" volume={Volume}/>
      </mesh>
      <mesh
        geometry={nodes.WieseSound004.geometry}
        material={materials.Unsichtbar}
        position={[2.54, 1.17, 4.25]}
        scale={0.1}
      >
        <Sound index={32} soundRefs={soundRefs} setSoundRefs={setSoundRefs} url="/audios/background/gras_noise_1.1.mp3" volume={Volume}/>
      </mesh>
    </group>
  );
}

useGLTF.preload("/3D/SoundBoxen.gltf");

export default SoundBoundingBoxen