import React from 'react'
import styled from 'styled-components';

const ModalWrapper = styled.div`
  width:100%;
  height:100%;
  color:black;
  display:flex;
  justify-content:center;
  align-items:center; 
  background:#ffffff21;
  position:absolute;
  z-index:5;
`;          

const ModalContent = styled.div`
  margin:10%; 
  background:white;
  position:relative;
  ${props => props.pb && `padding-bottom: ${props.pb}`};
`;

const CloseButtonWrapper = styled.div`
  z-index:100;
  cursor:pointer;
  position:absolute;
  background-color:white;
  bottom:0px;
  right:-77px;
  width:57px;
  height:57px;
  display:grid;
  place-items: center;
  font-size: 30px;
  >svg{
    width: 100%;
    height: 100%;
    stroke:black;
    stroke-width:2;
    &:hover{
      stroke-width:2.5;
      transform: scale(1.1);
    }
  }
`

const Modal = ({open, closeModal, children, pb}) => {
  return (
    <>
      {open && 
        <ModalWrapper onClick={closeModal} onContextMenu={(e) => e.preventDefault()} >
          <ModalContent pb={pb} onClick={(e) => e.stopPropagation()}>
            {children}
            <CloseButtonWrapper onClick={closeModal}>  
              <svg viewBox="0 0 40 40">
                <path  d="M 10,10 L 30,30 M 30,10 L 10,30" />
              </svg>
            </CloseButtonWrapper>
          </ModalContent>
        </ModalWrapper>
      }
    </>
  )
}

export default Modal