import React from 'react'
import ModalWrapper from './ModalWrapper'
import ModalTitle from './ModalTitle'
import styled from 'styled-components';


const quellen = [
  {
    title: "Ein ungewöhnliches Geschenk:",
    items:[
      "Jan Albert Sichterman, Philip van Dijck, 1745, (Wikimedia, Groninger Museum, Nr. 1992.0212, Public Domain)",
      "VOC Logo (Wikimedia, Public Domain)",
      "Karte von Südasien, 1792 (Rijksmuseum, Nr. NG-501-66, Public Domain)",
      "Sybilla Volkera Sichterman-Sadelijn, (Wikimedia, Groninger Museum Nr. 1992.0213, Public Domain)",
      "Niederländische Siedlung in Chinsura am Fluss Hugli in Westbengalen, Gravur William Hodges, 1787 (Wellcome Library, Nr. 26756i, Public Domain)",
      "Ansicht des Chandpal Ghat, James Baillie Frasier, 1826 (© Victoria & Albert Museum, London, Nr. IM.21-1918)",
      "Gründung der VOC 1602, Johann Heinrich Maria Hubert Rennefeld, nach Thomas Simon Cool, 1865-1870 (Rijksmuseum, Nr. RP-P-1944-2072, Public Domain)",
      "Baby Nashorn (© Schleich)",
      "Das Nashorn Clara, nach Anton August Beck, 1747 (Rijksmuseum, Nr. RP-P-1910-2144, Public Domain)",
      "Teller mit Wappen Familie Sichterman, 1730-35 (© J.G.A.N. de Vries Bequest, Den Haag, Rijksmuseum, Nr. AK-NM-13380)",
      "Tisch, 18. Jahrhundert (Rijksmuseum, Nr. BK-1961-84, Public Domain)",
      "Das Nashorn Clara, komm und sieh!, 1747 (Rijksmuseum, Nr. RP-P-OB-75.362, Public Domain)",
      "Noord-Nieuwland in der Tafelbucht, vmtl. 1762 (Iziko William Fehr Collection, Nr. CD 81, Public Domain)",
      "Zitrone und Orange, Jacques Le Moyne de Morgues, um 1575 (© Victoria and Albert Museum, London, Nr. AM.3267W-1856)",
      "Bier- oder Weinfass (Miniatur), um 1690‐1710 (Rijksmuseum, Nr. BK-NM-1010-302-A, Public Domain)",
      "Tabakblätter, Carl J. Kleingrothe, um 1898 (Rijksmuseum, Nr. RP-F-AA3189-27, Public Domain)",
      "Blick auf Rotterdam, nach 1666 (Rijksmuseum, Nr. RP-P-1894-A-18354B, Public Domain)",
      "Rhinozeros, Jean-Baptiste Oudry , 1749 (Wikimedia, Staatliches Museum Schwerin, Nr. G 1928, Public Domain)",
      "Karte von Europa im 18. Jahrhundert, 1735 (Rijksmuseum, Nr. BB01_BI-B-FM-090-3, Public Domain)",
      "Das Rhinozeros in Venedig, Pietro Longhi, 1751 (Wikimedia, The National Galley, London, Nr. NG1101, Public Domain)",
      "Nashorn, 1748 (Ailsa Mellon Bruce Fund, National Gallery of Art, Washington, Nr. 2010.32.1, Public Domain)",
      "Ein Rhinozeros namens Miss Clara, Statuette, 1740-1760 (The Salting Bequest © Victoria & Albert Museum, London, Nr. A.528-1910)"
    ]
  },
  {
    title:"Indigo durch Sklavenarbeit:",
    items:[
      "Junges Mädchen, Jean-Etienne Liotard, 1750-1755 (Rijksmuseum, Nr. SK-A-241, Public Domain)",
      "Herstellung von Indigo, Fumagalli, 19. Jahrhundert (© Science Museum Group)",
      "Sklaven auf einer Indigo-Plantage, Jean-Baptiste du Tertre, 1667-1671 (Bibliothèque nationale de France, Public Domain)",
      "Brief von Eliza Lucas Pinckney, 1744 (Library of Congress, Nr. 13002732, Public Domain)",
      "Brief von Eliza Lucas Pinckney, 1744 (Library of Congress, Nr. 13002732, Public Domain)",
      "Indigo-Pflanze, Gravur von J.J. oder J.E. Haid, 1750-1753 (Wellcome Collection, Public Domain)",
      "Indigo-Farbprobe, 19. Jahrhundert (© Science Museum Group)",
      "Herstellung von Indigo, gedruckt von Robert Bénard, 1784 (The New York Public Library Digital Collections, Public Domain)",
      "Textilhandel Anfang des 19. Jahrhunderts, Rudolph Ackermann, 1809 (The British Library Board, Public Domain)"
    ]
  },
  {
    title:"Cupido und Sideron:",
    items:[
      "Die Insel Curaçao, Atlas van der Hagen, um 1690 (Koninklijke Bibliotheek, Public Domain)",
      "Niederländischer Kaufmann mit zwei Sklaven, 1700-1725 (Rijksmuseum, Nr. SK-A-4988, Public Domain)",
      "„Der Sklavenhandel“, George Morland, gedruckt von John Raphael Smith, 1791 (Yale Center for British Art, Paul Mellon Collection, Nr. B1978.43.283, Public Domain)",
      "Sklaven auf einer Zuckerplantage in Antigua, William Clark, 1823 (© The British Library Board)",
      "Weltkarte, 1800 (Wikimedia, Public Domain)",
      "Gemälde eines Dieners (wahrscheinlich Sideron), Hendrik Pothoven, um 1780 (Rijksmuseum, erworben mit Unterstützung des I.Q. van Regteren Altena ",
      "Das Sklavenschiff Marie Séraphique, 1770 (© Chateau des ducs de Bretagne - Musée d'histoire de Nantes, Alain Guillard)",
      "Willem V und Wilhelmina von Preußen, Pieter Lesage, 1779 (Rijksmuseum, Nr. SK-A-4335, Public Domain)",
      "Willem V. und seine Familie in Den Haag, Hendrik Pothoven, 1781 (© Collection Haags Historisch Museum)",
      "Ein Diener (wahrscheinlich Cupido), Hendrik Pothoven, 1782-1785 (© Museum Boijmans van Beuningen, Rotterdam, Nr. MB 1941/T 18 (PK)",
      "Ein Diener (wahrscheinlich Sideron), Isaac Lodewijk la Fargue von Nieuwland, 1766 (Rijksmuseum, Nr. RP-T-00-3550, Public Domain)"
    ]
  },
  {
    title:"Austausch und Spionage:",
    items:[
      "Gewickeltes Kleidungsstück, Südindien, um 1855-1879 (© Victoria & Albert Museum, London, Nr. 4819 (IS)) ",
      "Rock aus blauer Baumwolle, Kangra/Indien, 1855 (© Victoria & Albert Museum, London, Nr. 05562 (IS))",
      "Palampore, Coromandel Küste/Indien, um 1725-1750 (© Victoria & Albert Museum, London, Nr. IS.10-1976)",
      "Christophe-Philippe Oberkampf, Jean Mathias Fontaine, um 1830 (Rijksmuseum, Nr. RP-P-1910-5075, Public Domain)",
      "Johann Heinrich Schüle, Sophonias de Derichs, 1772 (© Kunstsammlungen & Museen Augsburg, Foto: Andreas Brücklmair)",
      "Tagesdecke aus Baumwollsatin, um 1725-1750 (Rijksmuseum, Nr. BK-BR-933, Public Domain)",
      "Indische Weber bei der Arbeit, aus: James Skinner: History of the origin and distinguishing marks of the different Castes of India, 1825 (Library of Congress, African and Middle East Division, Near East Section Persian Manuscript Collection, Public Domain)",
      "Frauen im Mittelalter bei Spinnarbeiten, Giovanni Boccaccio, 15. Jahrhundert (© The British Library Board)",
      "Arbeiten mit Baumwolle, 1850-1860 (© The British Library Board)",
      "Englisches Handelsschiff, William Clark, 1836 (Yale Center for British Art, Paul Mellon Collection, Nr. B1981.25.106, Public Domain)",
      "Armenischer Händler, Jerzy Daniel Schultz, 1769, Sammlung der Mechitaristen-Kirche von San Lazaro in Venedig",
      "Seite aus The Textile Manufactures of India, John Forbes Watson, 1866 (Courtesy of the Harris Museum & Art Gallery, Preston, www.tmoi.org.uk)",
      "Karte von Europa im 18. Jahrhundert, 1735 (Rijksmuseum, Nr. BB01_BI-B-FM-090-3, Public Domain)",
      "Buchdrucker bei der Arbeit, Jan Collaert I, Jan van der Straet, um 1600 (Metropolitan Museum of Art, Nr. 34.30(5), Public Domain)",
      "Pressen für den Blockdruck von Kattun, Gravur von James Carter nach Thomas Allom, 1834 (Wellcome Collection, Nr. 44077i, Public Domain)",
      "Gedrucktes Textilmotiv aus der Manufaktur Oberkampf, gestaltet von Jean-Baptiste Huet, um 1800 (© Victoria & Albert Museum, London, Nr. T.449-1919)",
      "Textilmotiv aus Leinen und Baumwolle, hergestellt von Robert Jones, 1769 (Metropolitan Museum of Art, Nr. 1983.365, Public Domain)",
      "Bedrucktes Kleid der französischen Manufaktur Oberkampf, wahrscheinlich gestaltet von Jean-Baptiste Huet, um 1799 (Metropolitan Museum of Art, Purchase, Irene Lewisohn Bequest, 1960, Nr. C.I.60.26.3a, b)",
    ]
  },
  {
    title:"Harter Alltag auf hoher See:",
    items:[
      "Gedenktafel für die VOC in Hoorn (© Wikimedia-Nutzer: Stephencdickson)",
      "Heck des Nachbaus des VOC-Schiffes „Amsterdam“ (Foto: Swire Chin, Toronto/ Hong Kong)",
      "Bug des Nachbaus des VOC-Schiffes „Amsterdam“ (Foto: Swire Chin, Toronto/ Hong Kong)",
      "Deck des Nachbaus des VOC-Schiffes „Amsterdam“ (Foto: Swire Chin, Toronto/ Hong Kong)",
      "Nachbau des VOC-Schiffes „Amsterdam“, 2019 (© Dell Upton)",
      "Schiffbruch eines VOC-Schiffs, 1779 (Rijksmuseum, Nr. RP-P-OB-85.039, Public Domain)",
      "Modell der VOC-Werft in Amsterdam, Hans Bonke (u.a.), 1988-1989 (Rijksmuseum, Nr. NG-2001-20, Public Domain)",
      "Modell eines Schiffes, nach William May, 1798 (Rijksmuseum, Nr. NG-MC-501, Public Domain)",
      "Modell des Schiffes “Aurora” (© Chateau des ducs de Bretagne - Musée d'histoire de Nantes, Alain Guillard)",
      "Fässer im Nachbau der „Amsterdam“ (Foto: Swire Chin, Toronto/ Hong Kong)",
      "Der Handelsposten der Niederländischen Ostindien-Kompanie in Hugli, Bengalen, Hendrik van Schuylenburgh, 1665 (Rijksmuseum, Nr. SK-A-4282, Public Domain)",
      "Kupfermünze der VOC, 1792 (Rijksmuseum, on loan from the Koninklijk Oudheidkundig Genootschap, Nr. KOG-MP-1-4598, Public Domain)",
      "Schusswaffe der VOC, 1725-1798 (Rijksmuseum, Nr. NG-NM-3552, Public Domain)",
      "Uniformen der VOC-Soldaten, H. Rolland, 1783 (Nationaal Archief, Public Domain)",
      "Maus und Ratte, um 1560 (Rijksmuseum, Nr. RP-T-1952-359, Public Domain)",
      "Seemann, Caspar Luyken, 1698 (Rijksmuseum, Nr. RP-P-1896-A-19368-1599, Public Domain)",
      "Seemann, Albertus Verhoesen (zugeschrieben), 1835-1850 (Rijksmuseum, Nr. BI-B-FM-123-154, Public Domain)",
      "Essen der Mannschaft im Nachbau des VOC-Schiffes „Amsterdam“ (Foto: Swire Chin, Toronto/ Hong Kong)",
      "Essen des Kapitäns im Nachbau des VOC-Schiffes „Amsterdam“ (Foto: Swire Chin, Toronto/ Hong Kong)",
      "Hängematten im Nachbau des VOC-Schiffes „Amsterdam“ (Foto: Swire Chin, Toronto/ Hong Kong)",
      "Teller der VOC, 1650-1674 (Amsterdam Museum, Nr. KA 16219, Public Domain)",
      "Besteck der VOC, 1671 (Rijksmuseum, Nr. BK-1976-20-B, Public Domain)",
      "Nahrungsmittel, Willem Claesz Heda, 1634 (Rijksmuseum, Nr. SK-A-137, Public Domain)",
      "Nahrungsmittel, Georg Hainz, 1666-1700 (Rijksmuseum, Gift of the heirs of C. Hoogendijk, The Hague, Nr. SK-A-2550, Public Domain)",
      "Fische, Pieter van Noort, 1648-1672 (Rijksmuseum, Nr. SK-A-776, Public Domain)",
      "Zitrone und Orange, Jacques Le Moyne de Morgues, um 1575 (© Victoria and Albert Museum, London, Nr. AM.3267W-1856)",
      "Skorbut-Patienten, Robert Alan Thom, 1959 (From the collection of Michigan Medicine, University of Michigan, Gift of Pfizer, Inc., UMHS.17)",
      "Kiste mit Flaschen im Nachbau des VOC-Schiffes „Amsterdam“ (Foto: Swire Chin, Toronto/ Hong Kong)",
      "Deckansicht auf ein VOC-Schiff, Jan Brandes, 1778-1787 (Rijksmuseum, Nr. NG-1985-7-1-144, Public Domain)",
      "Deckansicht auf ein VOC-Schiff, Jan Brandes, 1779-1787 (Rijksmuseum, Nr. NG-1985-7-1-3, Public Domain)",
      "Nachbau der Bataviaa, 2007 (Wikimedia, Public Domain)",
      "Der historische Hafen von Amsterdam am Meeresarm IJ, Ludolf Bakhuysen, 1673, (Rijksmuseum, Nr. SK-A-9, Public Domain)",
      "Nahansicht Heck des Nachbaus des VOC-Schiffes „Amsterdam“ (Foto: Swire Chin, Toronto/ Hong Kong)",
      "VOC-Werft in Amsterdam, Ludolf Backhuysen, 1696 (Mauritshuis, The Hague)"
    ]
  },

]

const ContentWrapper = styled.div`
  max-height:800px;
  padding-left: 28px;
  padding-right: 28px;
  overflow:auto;
   @media(max-height:1000px){
    height:600px;
  }
`;

const Text = styled.p`
  color: black;
  font-family: 'Avenir Next Regular';
  font-size:16px;
  font-weight:normal;
  line-height:26px;
  overflow:auto;
  margin-top: 10px;
  >a{
    color:black;
  }
`;


function ProjektInfo(props) {
  const { show, closeModal } = props

  return (
    <ModalWrapper open={show} closeModal={closeModal}>
      <ModalTitle title={"Stoff Ohne Grenzen"}/>
      <ContentWrapper>
        <h2>Projektinfo</h2>
        <Text>
          Das Staatliche Textil- und Industriemuseum Augsburg (tim) widmet sich seit seiner Eröffnung der Geschichte der bayerischen Textilindustrie, die es in seiner weltweiten Vernetzung den Besucher:innen vor Augen führt. Damit macht das tim deutlich, dass die heutige Globalisierung auf historische Vorläufer zurückgeht. Das Kennenlernen dieser Art von Globalgeschichte erlaubt einen aufschlussreichen Blick auf die Entstehungsbedingungen der gegenwärtigen modernen Welt mit ihrer wirtschaftlichen Dynamik, ihren globalen Wirtschaftsinteressen, weltweiten Warenströmen, politischen Rahmenbedingungen und sozialen Umbrüchen. Das Projekt „Stoff ohne Grenzen - Mode bewegt die Welt“ lädt Nutzer:innen dazu ein, sich auf eine digitale Zeitreise durch die Globalgeschichte des 18. Jahrhunderts zu begeben und die Vernetzungen der damaligen Welt zu erkunden.
        </Text>
        <Text>
          Die interaktive Plattform bereitet im Ausgang von Augsburg die Globalgeschichte des 18. Jahrhunderts mit multimedialen Mitteln für die Nutzer:innen verständlich und lebendig auf. Im Zentrum der Website steht eine interaktive Weltkarte, mit Hilfe derer die Nutzer:innen unterschiedlichen Handelsrouten über den Globus hinweg folgen können. Neben Texten und Bildern aus der Sammlung des tim laden Audiosequenzen und Filmanimationen zu einer packenden Zeitreise durch den pulsierenden Handelskosmos ein. Spielerisch können die Nutzer:innen sich durch die digitale Welt bewegen und die spannende Geschichte zur Herstellung von Stoffen und zum textilen Handel im 18. Jahrhundert erkunden. Diese Geschichte vernetzt Akteur:innen weltweit und verbindet viele Länder des Erdballs miteinander. 
        </Text>
        <Text>
          „Stoff ohne Grenzen - Mode bewegt die Welt“ konnte im Rahmen von „dive in. Programm für digitale Interaktionen“ der Kulturstiftung des Bundes realisiert werden. Die Mittel stammen aus dem Rettungs- und Zukunftspaket der Beauftragten der Bundesregierung für Kultur und Medien (BKM) im Programm  <a href="https://www.kulturstiftung-des-bundes.de/de/projekte/erbe_und_vermittlung/detail/dive_in_programm_fuer_digitale_interaktionen.html" target="_blank"  rel="noreferrer" >NEUSTART KULTUR</a>.
        </Text>

        <h2>Projektbeteiligte</h2>
        <Text>tim. Anneli Kraft (Projektleitung), Dr. Karl Borromäus Murr, Dr. Michaela Breil, Mariama De Brito, Katja Cox, David Dorn</Text>
        <Text>projektpartner. <a href="https://labbinaer.de/" target="_blank" rel="noreferrer" >LAB BINÆR GBR. LAB FOR MEDIA ART</a></Text>
        <Text>gefördert. dive in. Programm für digitale Interaktionen. <a href="https://www.kulturstiftung-des-bundes.de/de" target="_blank"  rel="noreferrer" >Kulturstiftung des Bundes</a></Text>

        <h2>Bildquellen</h2>
        {quellen.map((quelle)=>(
          <React.Fragment key={`sources-${quelle.title}`}>
            <h3>{quelle.title}</h3>
          <ul>
            {quelle.items.map((item)=>(
              <li>{item}</li>
            ))}
          </ul>
          </React.Fragment>
        ))}
      </ContentWrapper>

    </ModalWrapper>
  )
}

export default ProjektInfo