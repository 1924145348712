import React, {useRef, useState, useEffect} from 'react'
import styled from 'styled-components';
import ModalWrapper from './ModalWrapper';
import { Swiper, SwiperSlide } from 'swiper/react'; // Import Swiper React components
import 'swiper/css'; // Import Swiper styles
import { EffectCoverflow, Navigation } from "swiper";
import ArrowSvg from "./Arrow.svg"
import ModalTitle from './ModalTitle';
import Caption from './Caption';
import { SideBySideMagnifier } from "react-image-magnifiers";


const Arrow = styled.div`
  height:57px;
  width:57px;
  background:white;
  display:grid;
  place-items:center;
  box-shadow: 0px 0px 15px #00000033;

`;

const Prev = styled(Arrow)`
  margin-left:20px;
  cursor:pointer;
  opacity:0;
  position:absolute;
  left:0px;
  z-index:99;
  top:320px;
  ${props => props.show && "opacity:1;"}
  @media(max-height: 1000px){
    top:245px;
  }
`;

const Next = styled(Arrow)`
  margin-right:20px;
  cursor:pointer;
  opacity:0;
  position:absolute;
  right:0px;
  z-index:99;
  top:320px;
  ${props => props.show && "opacity:1;"}
  @media(max-height: 1000px){
    top:245px;
  }
`;

const SlideText = styled.p`
  padding:0 38px;
  color: black;
  font-family: 'Avenir Next Regular';
  font-size:16px;
  font-weight:normal;
  line-height:26px;
  overflow:auto;
  margin-top: 10px;
  >a{
    color:black;
  }
`;

const ArrowImg = styled.img`
  ${props => props.flipped && "transform: rotate(180deg);"}
  width:30px;
`;


const CounterWrapper = styled.div`
  display:flex;
  font-family: 'Avenir Next Regular';
  justify-content:center;
  box-sizing:border-box;
  padding:0 38px;
  position:absolute;
  width:100%;
  bottom:25px;
  color:black;
`;

const SlideContentWrapper = styled.div`
  height:100%;
  @media(max-height:1000px){
    height:600px;
  }
`;

const TextWrapper = styled.div`
  overflow:auto;
  height:270px;
  @media(max-height:1000px){
    height:215px;
  }
`;


function SliderModal({open, closeModal, content}) {
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)
  const captionRef = useRef(null)
  const textRef = useRef(null)
  const [activeIndex, setActiveIndex] = useState(1)
  const { slides, title } = content;
  const [makeNavWork, setMakeNavWork] = useState(false)

  useEffect(() => {
    setMakeNavWork(true)
  }, [])
  
  
  return (
    <ModalWrapper pb={"30px"}open={open} closeModal={closeModal}>
      <ModalTitle title={title}/>
        <Prev ref={navigationNextRef} show={activeIndex !== 1} ><ArrowImg src={ArrowSvg}/></Prev>
        <Next ref={navigationPrevRef} show={activeIndex !== slides.length}><ArrowImg flipped src={ArrowSvg}/></Next>
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        modules={[EffectCoverflow, Navigation]}
        navigation={{
          // next and prev are flipped here for some reason
          prevEl: navigationNextRef.current,
          nextEl: navigationPrevRef.current,
        }}
        onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex+1)}
        onAfterInit={(swiper) => {
          // next and prev are flipped here for some reason
          swiper.params.navigation.prevEl = navigationNextRef.current;
          swiper.params.navigation.nextEl = navigationPrevRef.current;
        }}
      >
        {slides.map((slide,index) => (
          <SwiperSlide key={`slide-${index}`}>
            <SlideContentWrapper >
              <SideBySideMagnifier
                imageSrc={slide.imageSmall}
                imageAlt="Example"
                largeImageSrc={slide.imageBig} // Optional
                alwaysInPlace={true}
              />
              <TextWrapper>
                <Caption ref={captionRef}>  {slide.caption}</Caption>
                <SlideText ref={textRef} dangerouslySetInnerHTML={{__html: slide.text}}/>
              </TextWrapper>
            </SlideContentWrapper>
          </SwiperSlide>
        ))}
      </Swiper>
      <CounterWrapper>
            <div>{activeIndex}/{slides.length}</div>
      </CounterWrapper>
    </ModalWrapper>
  )
}

export default SliderModal