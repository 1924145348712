import React, { useRef, useState, createContext, useContext, useEffect } from 'react'
import styled from 'styled-components';


const SubtitlesWrapper = styled.div`
  position:absolute;
  bottom:133px;
  z-index:9999;
  width:100%;
  display:flex;
  justify-content:center;
`;

const SubtitlesInnerWrapper = styled.div`
  background:white;
  padding:10px;
`;

const Subtitles = styled.p`
  text-align:center;
  line-height:0;
`

const AudioContext = createContext(null)

export const AudioProvider = (props) => {
  const audioRef = useRef('initial')
  const { children } = props
  const [playing, setPlaying] = useState(true);
  const trackRef = useRef()
  const [subtitles, setSubtitles ] = useState()
  const [ source, setSource ] = useState()
  const [ trackSource, setTrackSource ] = useState()

  useEffect(()=> {
    if(trackRef?.current){
      trackRef.current.oncuechange = e => {
        const { track } = e.target;
        const { activeCues } = track;
        const text = [...activeCues].map(
          cue => cue.getCueAsHTML().textContent
        )[0];
        setSubtitles(text);
      };
    }
   
  },[trackSource])
  
  useEffect(()=>{
    if(audioRef.current && source !== ""){
      audioRef.current.pause();
      audioRef.current.load();
      audioRef.current.play();
    }
    if(trackSource){
      // necessary to show them in firefox see: 
      // https://bugzilla.mozilla.org/show_bug.cgi?id=1214027
      trackRef.current.track.mode = "showing"
    }
  },[source,trackSource])

  const Pause = () => {
    try{
      audioRef.current.pause();
    } catch (e) {
      console.log(e)
    }
  }

  const Play = () => {
    try{
      audioRef.current.play();
    } catch (e) {
      console.log(e)
    }
  }

  const setAudioSrc = (newSource, newTrackSource) => {
    try{
      if(newSource === source) return // bail if audio is already the one that should play
      setSource(newSource);
      setTrackSource(newTrackSource)
    } catch (e) {
      console.log(e)
    }
  }
 
  return (
    <AudioContext.Provider value={{setAudioSrc, setSubtitles, audioRef, Pause, Play, playing}}>
        <audio ref={audioRef} src={source} controls={false} autoPlay={true} onPlay={()=> setPlaying(true)} onPause={()=> setPlaying(false)} >
          {trackSource !== undefined && // necessary to show/relaod them in firefox  
            <track ref={trackRef} kind="captions" label="English SubTitles Dynamic" src={trackSource} srcLang="en" default />
          }
          Your browser does not support the audio tag.
        </audio>
        {subtitles &&
          <SubtitlesWrapper>
              <SubtitlesInnerWrapper>
                <Subtitles>
                  {subtitles}
                </Subtitles>
              </SubtitlesInnerWrapper>
          </SubtitlesWrapper>
        }
      {children}
    </AudioContext.Provider>
  );
}

export default function useAudio(){
  return useContext(AudioContext)
}

