import styled from 'styled-components';

const Caption = styled.p`
  padding-left:38px;
  padding-right:38px;
  margin:0;
  color:#00000065;
  font-style:italic;
  font-size:14px;
  margin-top:10px;
  font-family: 'Avenir Next Regular';
`;

export default Caption