import React from 'react'
import Grass0 from './Grass0'
import Grass1 from './Grass1'
import Grass2 from './Grass2'

function Grass() {
  return (
    <>
      <Grass0/>
      <Grass1/>
      <Grass2/>
    </>
  )
}

export default Grass