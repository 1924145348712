import React from 'react';
import styled from 'styled-components';
import useAudio from '../hooks/useAudioPlayer';

const ProgressContainer = styled.div`
  position: relative;
  width:320px;
  height:50px;  
  background-color:#707070;
  cursor:pointer;
  z-index:99;
  border:1px solid black;
  margin-bottom:20px;
`

const ProgressBarFiller = styled.div`
  width:${props => props.progress}%;
  background-color:white;
  height:100%;
  display:grid;
  align-items: center;
  transition: width 0.1s ease-in-out;
  span {
    color:black;
    padding-left:10px;
    font-size:21px;
    font-family: 'Avenir Next',sans-serif;
    font-weight: 700;
    min-width:200px;
    text-transform:uppercase;
  }
`

const ProgressBar = ({progress, close, soundRefs}) => {
  const { Play } = useAudio()

  const handleClick = (progress) => {
    progress === 100 && close()
    Play() // this is used to trick safari for a user interaction 
    soundRefs.current.map((sound)=> sound.play())
  }

 
  return (
    <ProgressContainer  onClick={() => handleClick(progress)}>
      <ProgressBarFiller progress={progress}>
        <span>{progress < 100 ? `Welt lädt...` : "Start ins Abenteuer"}</span>
      </ProgressBarFiller>
    </ProgressContainer>
  )
}

export default ProgressBar