import React, {useState} from 'react'
import styled from 'styled-components';

const PlayPauseButton = styled.div`
  // source: https://css-tricks.com/making-pure-css-playpause-button/
  border: 0;
  background: transparent;
  box-sizing: border-box;
  width: 0;
  height: 40px;

  border-color: transparent transparent transparent #202020;
  transition: 100ms all ease;
  cursor: pointer;

  // play state
  border-style: solid;
  border-width: 20px 0 20px 30px;

  ${props => props.playing && "border-style: double;"};
  ${props => props.playing && "border-width:0px 0 0px 40px;"};
  &:focus{
    outline:none !important
  }
`

const PlayPauseWrapper = styled.div`
  position:absolute;
  background:white;
  padding:10px;
  width:40px;
  height:40px;
  display:flex;
  justify-content:center;
  align-items:center;
`;


function PlayPause({playing,handleClick}) {

  return (
    <PlayPauseWrapper onClick={handleClick}>
      <PlayPauseButton playing={playing}/>
    </PlayPauseWrapper>
  )
}

export default PlayPause