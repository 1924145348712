import React, { useRef, useEffect } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { useFrame, useThree } from '@react-three/fiber'
import useArrayRef from "../hooks/useLetterRefArray";
import { useState } from "react";
import * as THREE from 'three'

function Letters(props) {
  const {handleClick} = props
  const group = useRef();
  const oldCloseLetterIndex = useRef()
  const { camera } = useThree()
  const { letters } = props
  const [ refs, setRef ] = useArrayRef();
  const [ closeLetterIndex, setCloseLetterIndex ] = useState(false)
  const { nodes, materials, animations } = useGLTF("/3D/briefe.gltf");
  const test = useGLTF("/3D/briefe_visited.gltf");
  const { actions } = useAnimations(animations, group)
  const [hovered, setHovered] = useState(false)
  const listener = new THREE.AudioListener();
  const sound = new THREE.Audio( listener );

  const playOpenSound = (index) => {
    if(oldCloseLetterIndex.current !== index){
      // create an AudioListener and add it to the camera
      camera.add( listener );
      // load a sound and set it as the Audio object's buffer
      const audioLoader = new THREE.AudioLoader();
      audioLoader.load( '/audios/background/unfolding.mp3', function( buffer ) {
        sound.setBuffer( buffer );
        sound.setLoop( false );
        sound.setVolume( 1 );
        sound.play();
      });
    }
    oldCloseLetterIndex.current = index
  }

  useEffect(() => {
    document.body.style.cursor = hovered ? 'pointer' : 'auto'
  }, [hovered])

  useEffect(() => { 
    if(closeLetterIndex){
      const paddedIndex = closeLetterIndex.toString().padStart(3,'0')
      const actionKeyName1 = `BriefBottomAction.${paddedIndex}`
      const actionKeyName2 = `BriefLeftAction.${paddedIndex}`
      const actionKeyName3 = `BriefRightAction.${paddedIndex}`
      if(!!actionKeyName1 && !!actionKeyName3 && !!actionKeyName2){

        actions[actionKeyName1].clampWhenFinished = true;
        actions[actionKeyName2].clampWhenFinished = true;
        actions[actionKeyName3].clampWhenFinished = true;
        actions[actionKeyName1].setLoop(1,1).play()
        actions[actionKeyName2].setLoop(1,1).play()
        actions[actionKeyName3].setLoop(1,1).play()

      }
      return () => {
          actions[actionKeyName2].stop()
          actions[actionKeyName3].stop()
          actions[actionKeyName1].stop()
      }
    }
    
  },[actions,closeLetterIndex])



  useFrame(( state ) => {
    const mesh2Position = camera.position
    const distances = refs.current.map((ref, index)=>{
      const mesh1Position = ref?.position
      ref.lookAt(camera.position) // make each letter look at camera
      return mesh1Position.distanceTo(mesh2Position)
    })
    const minDistance = Math.min(...distances)
    if(minDistance < 2 ){
      const minIndex = distances.indexOf(minDistance)
      playOpenSound(minIndex+1)
      setCloseLetterIndex(minIndex+1)
    }
  })

  const greyMaterial =  new THREE.MeshStandardMaterial({
      color: 0xf0f0f8,    // red (can also use a CSS color string here)
    });
    


  
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="brief001" position={[4.25, 0.21, 9.4]} ref={setRef} onClick={() => handleClick("all-over-the-world")} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
          <mesh
            name="bottom001"
            castShadow
            receiveShadow
            geometry={nodes.bottom002.geometry}
            material={!letters.find(letter => letter.id === "all-over-the-world").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.0078, -0.0752, 0.0021]}
            rotation={[-0.0789, -0.0241, -0.0015]}
          />
          <mesh
            name="left001"
            castShadow
            receiveShadow
            geometry={nodes.left002.geometry}
            material={!letters.find(letter => letter.id === "all-over-the-world").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[-0.1131, -0.0273, 0.0015]}
          />
          <mesh
            name="right001"
            castShadow
            receiveShadow
            geometry={nodes.right002.geometry}
            material={!letters.find(letter => letter.id === "all-over-the-world").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.1112, 0.038, 0.002]}
          />
          <mesh
            name="back001"
            castShadow
            receiveShadow
            geometry={nodes.back002.geometry}
            material={!letters.find(letter => letter.id === "all-over-the-world").visited ? materials["BriefThumbnail.002"] : test.materials["BriefVisited.002"]}
          />
        </group>
        <group name="brief002" position={[12.82, 0.21, 1.02]} ref={setRef} onClick={() => handleClick("present")} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
          <mesh
            name="bottom002"
            castShadow
            receiveShadow
            geometry={nodes.bottom001.geometry}
            material={!letters.find(letter => letter.id === "present").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.0078, -0.0752, 0.0021]}
            rotation={[-0.0789, -0.0241, -0.0015]}
          />
          <mesh
            name="left002"
            castShadow
            receiveShadow
            geometry={nodes.left001.geometry}
            material={!letters.find(letter => letter.id === "present").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[-0.1131, -0.0273, 0.0015]}
          />
          <mesh
            name="right002"
            castShadow
            receiveShadow
            geometry={nodes.right001.geometry}
            material={!letters.find(letter => letter.id === "present").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.1112, 0.038, 0.002]}
          />
          <mesh
            name="back002"
            castShadow
            receiveShadow
            geometry={nodes.back001.geometry}
            material={!letters.find(letter => letter.id === "present").visited ? materials["BriefThumbnail.001"] : test.materials["BriefVisited.001"]}
          />
        </group>
        <group name="brief003" position={[2.19, 0.21, -8.61]} ref={setRef} onClick={() => handleClick("schoenste-rot")} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
          <mesh
            name="bottom003"
            castShadow
            receiveShadow
            geometry={nodes.bottom003.geometry}
            material={!letters.find(letter => letter.id === "schoenste-rot").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.0078, -0.0752, 0.0021]}
            rotation={[-0.0789, -0.0241, -0.0015]}
          />
          <mesh
            name="left003"
            castShadow
            receiveShadow
            geometry={nodes.left003.geometry}
            material={!letters.find(letter => letter.id === "schoenste-rot").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[-0.1131, -0.0273, 0.0015]}
          />
          <mesh
            name="right003"
            castShadow
            receiveShadow
            geometry={nodes.right003.geometry}
            material={!letters.find(letter => letter.id === "schoenste-rot").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.1112, 0.038, 0.002]}
          />
          <mesh
            name="back003"
            castShadow
            receiveShadow
            geometry={nodes.back003.geometry}
            material={!letters.find(letter => letter.id === "schoenste-rot").visited ? materials["BriefThumbnail.003"] : test.materials["BriefVisited.003"]}
          />
        </group>
        <group name="brief004" position={[-13.07, 0.21, -1.21]} ref={setRef} onClick={() => handleClick("laus")} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
          <mesh
            name="bottom004"
            castShadow
            receiveShadow
            geometry={nodes.bottom004.geometry}
            material={!letters.find(letter => letter.id === "laus").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.0078, -0.0752, 0.0021]}
            rotation={[-0.0789, -0.0241, -0.0015]}
          />
          <mesh
            name="left004"
            castShadow
            receiveShadow
            geometry={nodes.left004.geometry}
            material={!letters.find(letter => letter.id === "laus").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[-0.1131, -0.0273, 0.0015]}
          />
          <mesh
            name="right004"
            castShadow
            receiveShadow
            geometry={nodes.right004.geometry}
            material={!letters.find(letter => letter.id === "laus").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.1112, 0.038, 0.002]}
          />
          <mesh
            name="back004"
            castShadow
            receiveShadow
            geometry={nodes.back004.geometry}
            material={!letters.find(letter => letter.id === "laus").visited ? materials["BriefThumbnail.004"] : test.materials["BriefVisited.004"]}
          />
        </group>
        <group name="brief005" position={[-7.78, 0.21, 3.87]} ref={setRef} onClick={() => handleClick("gum-wars")} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
          <mesh
            name="bottom005"
            castShadow
            receiveShadow
            geometry={nodes.bottom005.geometry}
            material={!letters.find(letter => letter.id === "gum-wars").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.0078, -0.0752, 0.0021]}
            rotation={[-0.0789, -0.0241, -0.0015]}
          />
          <mesh
            name="left005"
            castShadow
            receiveShadow
            geometry={nodes.left005.geometry}
            material={!letters.find(letter => letter.id === "gum-wars").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[-0.1131, -0.0273, 0.0015]}
          />
          <mesh
            name="right005"
            castShadow
            receiveShadow
            geometry={nodes.right005.geometry}
            material={!letters.find(letter => letter.id === "gum-wars").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.1112, 0.038, 0.002]}
          />
          <mesh
            name="back005"
            castShadow
            receiveShadow
            geometry={nodes.back005.geometry}
            material={!letters.find(letter => letter.id === "gum-wars").visited ? materials["BriefThumbnail.005"] : test.materials["BriefVisited.005"]}
          />
        </group>
        <group name="brief006" position={[11.87, 0.21, -7.82]} ref={setRef} onClick={() => handleClick("big-business")} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
          <mesh
            name="bottom006"
            castShadow
            receiveShadow
            geometry={nodes.bottom006.geometry}
            material={!letters.find(letter => letter.id === "big-business").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.0078, -0.0752, 0.0021]}
            rotation={[-0.0789, -0.0241, -0.0015]}
          />
          <mesh
            name="left006"
            castShadow
            receiveShadow
            geometry={nodes.left006.geometry}
            material={!letters.find(letter => letter.id === "big-business").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[-0.1131, -0.0273, 0.0015]}
          />
          <mesh
            name="right006"
            castShadow
            receiveShadow
            geometry={nodes.right006.geometry}
            material={!letters.find(letter => letter.id === "big-business").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.1112, 0.038, 0.002]}
          />
          <mesh
            name="back006"
            castShadow
            receiveShadow
            geometry={nodes.back006.geometry}
            material={!letters.find(letter => letter.id === "big-business").visited ? materials["BriefThumbnail.006"] : test.materials["BriefVisited.006"]}
          />
        </group>
        <group name="brief007" position={[-7.93, 0.21, 9.67]} ref={setRef} onClick={() => handleClick("baum-traenen")} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
          <mesh
            name="bottom007"
            castShadow
            receiveShadow
            geometry={nodes.bottom007.geometry}
            material={!letters.find(letter => letter.id === "baum-traenen").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.0078, -0.0752, 0.0021]}
            rotation={[-0.0789, -0.0241, -0.0015]}
          />
          <mesh
            name="left007"
            castShadow
            receiveShadow
            geometry={nodes.left007.geometry}
            material={!letters.find(letter => letter.id === "baum-traenen").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[-0.1131, -0.0273, 0.0015]}
          />
          <mesh
            name="right007"
            castShadow
            receiveShadow
            geometry={nodes.right007.geometry}
            material={!letters.find(letter => letter.id === "baum-traenen").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.1112, 0.038, 0.002]}
          />
          <mesh
            name="back007"
            castShadow
            receiveShadow
            geometry={nodes.back007.geometry}
            material={!letters.find(letter => letter.id === "baum-traenen").visited ? materials["BriefThumbnail.007"] : test.materials["BriefVisited.007"]}
          />
        </group>
        <group name="brief008" position={[-7, 0.21, 6.32]} ref={setRef} onClick={() => handleClick("augsburg-curacao")} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
          <mesh
            name="bottom008"
            castShadow
            receiveShadow
            geometry={nodes.bottom008.geometry}
            material={!letters.find(letter => letter.id === "augsburg-curacao").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.0078, -0.0752, 0.0021]}
            rotation={[-0.0789, -0.0241, -0.0015]}
          />
          <mesh
            name="left008"
            castShadow
            receiveShadow
            geometry={nodes.left008.geometry}
            material={!letters.find(letter => letter.id === "augsburg-curacao").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[-0.1131, -0.0273, 0.0015]}
          />
          <mesh
            name="right008"
            castShadow
            receiveShadow
            geometry={nodes.right008.geometry}
            material={!letters.find(letter => letter.id === "augsburg-curacao").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.1112, 0.038, 0.002]}
          />
          <mesh
            name="back008"
            castShadow
            receiveShadow
            geometry={nodes.back008.geometry}
            material={!letters.find(letter => letter.id === "augsburg-curacao").visited ? materials["BriefThumbnail.008"] : test.materials["BriefVisited.008"]}
          />
        </group>
        <group name="brief009" position={[9.46, 0.21, 4.78]} ref={setRef} onClick={() => handleClick("textillieferanten")} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
          <mesh
            name="bottom009"
            castShadow
            receiveShadow
            geometry={nodes.bottom009.geometry}
            material={!letters.find(letter => letter.id === "textillieferanten").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.0078, -0.0752, 0.0021]}
            rotation={[-0.0789, -0.0241, -0.0015]}
          />
          <mesh
            name="left009"
            castShadow
            receiveShadow
            geometry={nodes.left009.geometry}
            material={!letters.find(letter => letter.id === "textillieferanten").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[-0.1131, -0.0273, 0.0015]}
          />
          <mesh
            name="right009"
            castShadow
            receiveShadow
            geometry={nodes.right009.geometry}
            material={!letters.find(letter => letter.id === "textillieferanten").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.1112, 0.038, 0.002]}
          />
          <mesh
            name="back009"
            castShadow
            receiveShadow
            geometry={nodes.back009.geometry}
            material={!letters.find(letter => letter.id === "textillieferanten").visited ? materials["BriefThumbnail.009"] : test.materials["BriefVisited.009"]}
          />
        </group>
        <group name="brief010" position={[-7.81, 0.21, -11.27]} ref={setRef} onClick={() => handleClick("cupido")} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
          <mesh
            name="bottom010"
            castShadow
            receiveShadow
            geometry={nodes.bottom010.geometry}
            material={!letters.find(letter => letter.id === "cupido").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.0078, -0.0752, 0.0021]}
            rotation={[-0.0789, -0.0241, -0.0015]}
          />
          <mesh
            name="left010"
            castShadow
            receiveShadow
            geometry={nodes.left010.geometry}
            material={!letters.find(letter => letter.id === "cupido").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[-0.1131, -0.0273, 0.0015]}
          />
          <mesh
            name="right010"
            castShadow
            receiveShadow
            geometry={nodes.right010.geometry}
            material={!letters.find(letter => letter.id === "cupido").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.1112, 0.038, 0.002]}
          />
          <mesh
            name="back010"
            castShadow
            receiveShadow
            geometry={nodes.back010.geometry}
            material={!letters.find(letter => letter.id === "cupido").visited ? materials["BriefThumbnail.010"] : test.materials["BriefVisited.010"]}
          />
        </group>
        <group name="brief011" position={[7.27, 0.21, 2.6]} ref={setRef} onClick={() => handleClick("spionage")} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
          <mesh
            name="bottom011"
            castShadow
            receiveShadow
            geometry={nodes.bottom011.geometry}
            material={!letters.find(letter => letter.id === "spionage").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.0078, -0.0752, 0.0021]}
            rotation={[-0.0789, -0.0241, -0.0015]}
          />
          <mesh
            name="left011"
            castShadow
            receiveShadow
            geometry={nodes.left011.geometry}
            material={!letters.find(letter => letter.id === "spionage").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[-0.1131, -0.0273, 0.0015]}
          />
          <mesh
            name="right011"
            castShadow
            receiveShadow
            geometry={nodes.right011.geometry}
            material={!letters.find(letter => letter.id === "spionage").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.1112, 0.038, 0.002]}
          />
          <mesh
            name="back011"
            castShadow
            receiveShadow
            geometry={nodes.back011.geometry}
            material={!letters.find(letter => letter.id === "spionage").visited ? materials["BriefThumbnail.011"] : test.materials["BriefVisited.011"]}
          />
        </group>
        <group name="brief012" position={[0.13, 0.21, -2.86]} ref={setRef} onClick={() => handleClick("puppe")} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
          <mesh
            name="bottom012"
            castShadow
            receiveShadow
            geometry={nodes.bottom012.geometry}
            material={!letters.find(letter => letter.id === "puppe").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.0078, -0.0752, 0.0021]}
            rotation={[-0.0789, -0.0241, -0.0015]}
          />
          <mesh
            name="left012"
            castShadow
            receiveShadow
            geometry={nodes.left012.geometry}
            material={!letters.find(letter => letter.id === "puppe").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[-0.1131, -0.0273, 0.0015]}
          />
          <mesh
            name="right012"
            castShadow
            receiveShadow
            geometry={nodes.right012.geometry}
            material={!letters.find(letter => letter.id === "puppe").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.1112, 0.038, 0.002]}
          />
          <mesh
            name="back012"
            castShadow
            receiveShadow
            geometry={nodes.back012.geometry}
            material={!letters.find(letter => letter.id === "puppe").visited ? materials["BriefThumbnail.012"] : test.materials["BriefVisited.012"]}
          />
        </group>
        <group name="brief013" position={[0.22, 0.21, 3.75]} ref={setRef} onClick={() => handleClick("arbeitstag")} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
          <mesh
            name="bottom013"
            castShadow
            receiveShadow
            geometry={nodes.bottom013.geometry}
            material={!letters.find(letter => letter.id === "arbeitstag").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.0078, -0.0752, 0.0021]}
            rotation={[-0.0789, -0.0241, -0.0015]}
          />
          <mesh
            name="left013"
            castShadow
            receiveShadow
            geometry={nodes.left013.geometry}
            material={!letters.find(letter => letter.id === "arbeitstag").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[-0.1131, -0.0273, 0.0015]}
          />
          <mesh
            name="right013"
            castShadow
            receiveShadow
            geometry={nodes.right013.geometry}
            material={!letters.find(letter => letter.id === "arbeitstag").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.1112, 0.038, 0.002]}
          />
          <mesh
            name="back013"
            castShadow
            receiveShadow
            geometry={nodes.back013.geometry}
            material={!letters.find(letter => letter.id === "arbeitstag").visited ? materials["BriefThumbnail.013"] : test.materials["BriefVisited.013"]}
          />
        </group>
        <group name="brief014" position={[5.38, 0.21, 5.24]} ref={setRef} onClick={() => handleClick("morgenrock")} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
          <mesh
            name="bottom014"
            castShadow
            receiveShadow
            geometry={nodes.bottom014.geometry}
            material={!letters.find(letter => letter.id === "morgenrock").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.0078, -0.0752, 0.0021]}
            rotation={[-0.0789, -0.0241, -0.0015]}
          />
          <mesh
            name="left014"
            castShadow
            receiveShadow
            geometry={nodes.left014.geometry}
            material={!letters.find(letter => letter.id === "morgenrock").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[-0.1131, -0.0273, 0.0015]}
          />
          <mesh
            name="right014"
            castShadow
            receiveShadow
            geometry={nodes.right014.geometry}
            material={!letters.find(letter => letter.id === "morgenrock").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.1112, 0.038, 0.002]}
          />
          <mesh
            name="back014"
            castShadow
            receiveShadow
            geometry={nodes.back014.geometry}
            material={!letters.find(letter => letter.id === "morgenrock").visited ? materials["BriefThumbnail.014"] : test.materials["BriefVisited.014"]}
          />
        </group>
        <group name="brief015" position={[-13.01, 0.21, -7.16]} ref={setRef} onClick={() => handleClick("indigo")} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
          <mesh
            name="bottom015"
            castShadow
            receiveShadow
            geometry={nodes.bottom015.geometry}
            material={!letters.find(letter => letter.id === "indigo").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.0078, -0.0752, 0.0021]}
            rotation={[-0.0789, -0.0241, -0.0015]}
          />
          <mesh
            name="left015"
            castShadow
            receiveShadow
            geometry={nodes.left015.geometry}
            material={!letters.find(letter => letter.id === "indigo").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[-0.1131, -0.0273, 0.0015]}
          />
          <mesh
            name="right015"
            castShadow
            receiveShadow
            geometry={nodes.right015.geometry}
            material={!letters.find(letter => letter.id === "indigo").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.1112, 0.038, 0.002]}
          />
          <mesh
            name="back015"
            castShadow
            receiveShadow
            geometry={nodes.back015.geometry}
            material={!letters.find(letter => letter.id === "indigo").visited ? materials["BriefThumbnail.015"] : test.materials["BriefVisited.015"]}
          />
        </group>
        <group name="brief016" position={[-6.1, 0.21, -6.87]} ref={setRef} onClick={() => handleClick("sea")} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
          <mesh
            name="bottom016"
            castShadow
            receiveShadow
            geometry={nodes.bottom016.geometry}
            material={!letters.find(letter => letter.id === "sea").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.0078, -0.0752, 0.0021]}
            rotation={[-0.0789, -0.0241, -0.0015]}
          />
          <mesh
            name="left016"
            castShadow
            receiveShadow
            geometry={nodes.left016.geometry}
            material={!letters.find(letter => letter.id === "sea").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[-0.1131, -0.0273, 0.0015]}
          />
          <mesh
            name="right016"
            castShadow
            receiveShadow
            geometry={nodes.right016.geometry}
            material={!letters.find(letter => letter.id === "sea").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.1112, 0.038, 0.002]}
          />
          <mesh
            name="back016"
            castShadow
            receiveShadow
            geometry={nodes.back016.geometry}
            material={!letters.find(letter => letter.id === "sea").visited ? materials["BriefThumbnail.016"] : test.materials["BriefVisited.016"]}
          />
        </group>
        <group name="brief017" position={[2.42, 0.21, -3.8]} ref={setRef} onClick={() => handleClick("musterbuecher")} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
          <mesh
            name="bottom017"
            castShadow
            receiveShadow
            geometry={nodes.bottom017.geometry}
            material={!letters.find(letter => letter.id === "musterbuecher").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.0078, -0.0752, 0.0021]}
            rotation={[-0.0789, -0.0241, -0.0015]}
          />
          <mesh
            name="left017"
            castShadow
            receiveShadow
            geometry={nodes.left017.geometry}
            material={!letters.find(letter => letter.id === "musterbuecher").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[-0.1131, -0.0273, 0.0015]}
          />
          <mesh
            name="right017"
            castShadow
            receiveShadow
            geometry={nodes.right017.geometry}
            material={!letters.find(letter => letter.id === "musterbuecher").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.1112, 0.038, 0.002]}
          />
          <mesh
            name="back017"
            castShadow
            receiveShadow
            geometry={nodes.back017.geometry}
            material={!letters.find(letter => letter.id === "musterbuecher").visited ? materials["BriefThumbnail.017"] : test.materials["BriefVisited.017"]}
          />
        </group>
        <group name="brief018" position={[4.04, 0.21, 1.42]} ref={setRef} onClick={() => handleClick("lieferprobleme")} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
          <mesh
            name="bottom018"
            castShadow
            receiveShadow
            geometry={nodes.bottom018.geometry}
            material={!letters.find(letter => letter.id === "lieferprobleme").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.0078, -0.0752, 0.0021]}
            rotation={[-0.0789, -0.0241, -0.0015]}
          />
          <mesh
            name="left018"
            castShadow
            receiveShadow
            geometry={nodes.left018.geometry}
            material={!letters.find(letter => letter.id === "lieferprobleme").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[-0.1131, -0.0273, 0.0015]}
          />
          <mesh
            name="right018"
            castShadow
            receiveShadow
            geometry={nodes.right018.geometry}
            material={!letters.find(letter => letter.id === "lieferprobleme").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.1112, 0.038, 0.002]}
          />
          <mesh
            name="back018"
            castShadow
            receiveShadow
            geometry={nodes.back018.geometry}
            material={!letters.find(letter => letter.id === "lieferprobleme").visited ? materials["BriefThumbnail.018"] : test.materials["BriefVisited.018"]}
          />
        </group>
        <group name="brief019" position={[-12.3, 0.21, 9.34]} ref={setRef} onClick={() => handleClick("sklavenschiffe")} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
          <mesh
            name="bottom019"
            castShadow
            receiveShadow
            geometry={nodes.bottom019.geometry}
            material={!letters.find(letter => letter.id === "sklavenschiffe").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.0078, -0.0752, 0.0021]}
            rotation={[-0.0789, -0.0241, -0.0015]}
          />
          <mesh
            name="left019"
            castShadow
            receiveShadow
            geometry={nodes.left019.geometry}
            material={!letters.find(letter => letter.id === "sklavenschiffe").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[-0.1131, -0.0273, 0.0015]}
          />
          <mesh
            name="right019"
            castShadow
            receiveShadow
            geometry={nodes.right019.geometry}
            material={!letters.find(letter => letter.id === "sklavenschiffe").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.1112, 0.038, 0.002]}
          />
          <mesh
            name="back019"
            castShadow
            receiveShadow
            geometry={nodes.back019.geometry}
            material={!letters.find(letter => letter.id === "sklavenschiffe").visited ? materials["BriefThumbnail.019"] : test.materials["BriefVisited.019"]}
          />
        </group>
        <group name="brief020" position={[2.73, 0.21, -5.95]} ref={setRef} onClick={() => handleClick("unternehmer")} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
          <mesh
            name="bottom020"
            castShadow
            receiveShadow
            geometry={nodes.bottom020.geometry}
            material={!letters.find(letter => letter.id === "unternehmer").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.0078, -0.0752, 0.0021]}
            rotation={[-0.0789, -0.0241, -0.0015]}
          />
          <mesh
            name="left020"
            castShadow
            receiveShadow
            geometry={nodes.left020.geometry}
            material={!letters.find(letter => letter.id === "unternehmer").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[-0.1131, -0.0273, 0.0015]}
          />
          <mesh
            name="right020"
            castShadow
            receiveShadow
            geometry={nodes.right020.geometry}
            material={!letters.find(letter => letter.id === "unternehmer").visited ? materials["Bake Final.001"] : greyMaterial }
            position={[0.1112, 0.0380, 0.00195]}
          />
          <mesh
            name="back020"
            castShadow
            receiveShadow
            geometry={nodes.back020.geometry}
            material={!letters.find(letter => letter.id === "unternehmer").visited ? materials["BriefThumbnail.020"] : test.materials["BriefVisited.020"]}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/3D/briefe.gltf");
useGLTF.preload("/3D/briefe_visited.gltf");

export default Letters