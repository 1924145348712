import React, {useRef, useState} from 'react'
import styled from 'styled-components';
import ModalWrapper from './ModalWrapperVideo'
import PlayPauseButton from '../player/PlayPauseButton';
import ProgressBar from '../player/ProgressBar';

const Video = styled.video`
  height: min(90vh,960px);
  max-width: 540px;
`

const ProgressWrapper = styled.div`
  position:absolute;
  bottom:20px;
  width:95%;
  left:50%;
  transform:translateX(-50%);
`

const VideoWrapper = styled.div`
  position:relative;
  margin-bottom: -6px;
`;

const PlayPauseWrapper = styled.div`
  position:absolute;
  background:white;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  padding:10px;
  width:40px;
  height:40px;
  display:flex;
  justify-content:center;
  align-items:center;
`;

const TitleWrapper = styled.div`
  position:absolute;
  width:100%;
  top:0;
  height:130px;
  z-index:999;
  background: linear-gradient(180deg, rgba(0,0,0,0.75) 0%, rgba(255,255,255,0) 100%);
  >h2{
    margin:0;
    padding:10px 20px;
    font-family:"Avenir Next Medium";
    font-size:40px;
    line-height: 51px;
    font-style: italic;
    font-weight:500;
    letter-spacing: 1.68px;
    color: #FFFFFF;
    text-transform: uppercase;
    @media(max-height:850px){
      font-size:30px;
      line-height: 40px;
    }
    @media(max-height:620px){
      font-size:24px;
      line-height: 30px;
    }
  }
`

const Modal = ({open, closeModal, content}) => {
  const videoRef = useRef()
  const timeoutRef = useRef()
  const [playing, setPlaying] = useState(true);
  const [displayControls, setDisplayControls] = useState(false)

  const handlePointerMove = () => {
    clearTimeout(timeoutRef.current) 
    setDisplayControls(true)
    timeoutRef.current = setTimeout(() => {
      setDisplayControls(false)
    }, 1000)
  }

  const PlayPause = () => {
    if (!playing) {
      videoRef.current.play();
      setPlaying(true)
    } else if (playing) {
      videoRef.current.pause();
      setPlaying(false)
    }
  }

  const handleVideoClick = () => {
    handlePointerMove()
    PlayPause()
  }

  
  return (
    <>
      {open && 
       
        <ModalWrapper open={true}  closeModal={closeModal}>
            {displayControls && <TitleWrapper><h2>{content.title}</h2></TitleWrapper>}
            <VideoWrapper onPointerMove={handlePointerMove} onClick={handleVideoClick}>
              <Video controls={false} ref={videoRef} autoPlay={true}>
                <source src={content.videoSrc} type="video/mp4"/>
                Your browser does not support the video tag.
              </Video> 
              {displayControls &&
                <>
                  <PlayPauseWrapper>
                    <PlayPauseButton playing={playing} onClick={PlayPause}/>
                  </PlayPauseWrapper>
                  <ProgressWrapper>
                    <ProgressBar playerRef={videoRef}/>
                  </ProgressWrapper>
                </> 
              }
            </VideoWrapper>
        </ModalWrapper>
      }
    </>
  )
}

export default Modal