import React from 'react'
import {Helmet} from "react-helmet";
import faviconImg from './calico-favicon-32x32.png'

function HelmetWrapper() {
  return (
    <Helmet title={"Stoff ohne Grenzen - Mode bewegt die Welt"}>
      <link rel="icon" type="image/png" href={faviconImg} sizes="16x16" />
      <script type="text/javascript"/>
      <script id="_etLoader" type="text/javascript" charset="UTF-8" data-block-cookies="true" data-respect-dnt="true" data-secure-code="4aKM3g" src="//code.etracker.com/code/e.js" async></script>
    </Helmet>
  )
}

export default HelmetWrapper