import React from 'react'
import styled from 'styled-components';
import Tutorial from './Tutorial'
import BgImg from './background.jpg'
import TimLogo from './tim-logo-schwarz-sml.png'
import LogoBadgeSrc from './logos-final-2.jpg'

const TutorialWrapper = styled.div`
  position:absolute;
  left:50%;
  top:50%;
  z-index:9999;
  transform:translate(-50%,-30%);
`;

const Logo = styled.img`
  position:absolute;
  width:120px;
  top:0px;
  left:00px;
  z-index:999;
`;

const LogoBadge = styled.img`
  position:absolute;
  bottom:50px;
  right:50px;
  width:15vh ;
  max-width:190px;
`;

const Wrapper = styled.div`
  position:fixed;
  min-height:100vh;
  overflow:auto;
  width:100vw;
  z-index:999;
  background:white;
  background-image:url(${BgImg});
  background-size:cover;
 
`;


const ContentWrapper = styled.div`
  top:50px;
  left:50px;
  @media(max-width: 1300px){
    width:1000px;
  }
  @media(min-width: 2500px){
    left:10%;
  }
  @media(min-width: 2000px){
    left:5%;
  }
  width:1200px;
  display:flex;
  flex-direction:column;
  align-items:center;
  position: absolute;
  left:50px;
`;

const TopWrapper = styled.div`


`;

const BottomWrapper = styled.div`
  display:flex;
  flex-direction:column;
  margin-top:20px;
  width:100%;
`;

const TextWrapper = styled.div`
  max-width:400px;
  @media(max-width:1450px){
    max-width:350px;
  }
  @media(max-width:1220px){
    max-width:270px;
  }
  
  font: normal normal normal 16px/26px "Avenir Next Regular";
`;

const HeadlineWrapper = styled.div`
  text-transform:uppercase;
  position:relative;
  display: flex;
  justify-content: end;
  margin-left: -150px;
  >div{
    display:inline-block ;
  }
`;

const TopTitleWrapper = styled.div`
  font-family:'Avenir Next Bold';
  >h1{
    margin:0px;
    line-height:13.9vh;
    font-size:13.9vh;
  }
`
;
const BotTitleWrapper = styled.div`
  display:flex;
  justify-content: end;
  transform: translateX(150px);
`;

const Subtitle = styled.h2`
  font-family: "Avenir Next Medium";
  margin:0px;
  margin-bottom: 20px;
  font-size:min(5vh,42px);
  font-weight:300;
  font-style: italic;
  color: #000000;
  text-transform: uppercase;
`;

const World = styled.div`
  >h1{
    font-size:min(10vh,110px);

    line-height: 10vh;
    font-family:'Avenir Next Bold';
    margin-left: 20px;
    margin-top:0;
    margin-bottom:0;
  }

`;

const OfThe = styled.div`
  >h1{
    line-height:5vh;
    font-family:'Avenir Next Bold';
    font-size:min(5vh,42px);
    margin-top:-5px;
    margin-bottom:0;
  }
`;

const InnerWrapper = styled.div`
  display:flex;
  gap:20px;
  @media(max-width:1450px){
    max-width:350px;
  }
`;

const LeftTextWrapper = styled.div`
  
`;

function Intro({loadingProgress, close, soundRefs}) {

  return (
    <>
    <TutorialWrapper>
    <Tutorial progress={loadingProgress} close={close} soundRefs={soundRefs}/>
    </TutorialWrapper>
    <Wrapper>
      <a href="https://www.kulturstiftung-des-bundes.de/de/projekte/erbe_und_vermittlung/detail/dive_in_programm_fuer_digitale_interaktionen.html" target="_blank" rel="noreferrer">
        <LogoBadge src={LogoBadgeSrc}/>
      </a>
      <ContentWrapper>
        <a href="https://www.timbayern.de/" target="_blank" rel="noreferrer">
          <Logo src={TimLogo}/>
        </a>
        <TopWrapper>
          <HeadlineWrapper>
            <div>
              <TopTitleWrapper>
                <h1>Stoff</h1>
              </TopTitleWrapper>
              <BotTitleWrapper>
                <OfThe><h1>ohne</h1></OfThe>
                <World><h1>Grenzen</h1></World>
              </BotTitleWrapper>
            </div>

          </HeadlineWrapper>
        </TopWrapper>
        <BottomWrapper>
          <InnerWrapper>
            <LeftTextWrapper>
              <Subtitle>MODE BEWEGT DIE WELT</Subtitle>
              <TextWrapper>Komm mit auf eine packende Zeitreise und entdecke die spannende Geschichte der Stoffproduktion und des Textilhandels im 18.&nbsp;Jahrhundert – eine Geschichte, die viele Länder des Erdballs miteinander verbindet. So gelangen Techniken zur Herstellung und Bearbeitung von Stoffen von Indien nach Europa und in die ganze Welt. Doch warum benötigt eine deutsche Manufaktur Gummi von afrikanischen Bäumen, um ihre Stoffe zu bearbeiten? Warum tragen vornehme Damen in Paris Kleider aus Indien? Weshalb geht ein Nashorn auf Weltreise? Und wieso schleichen sich Spione auf eine Indigo-Plantage in South Carolina? Finde es heraus!</TextWrapper>
            </LeftTextWrapper>
          </InnerWrapper>
        </BottomWrapper>
      
      </ContentWrapper>
    </Wrapper>

    </>
  )
}

export default Intro