import * as THREE from 'three'
import React, { useRef, useEffect, useState } from 'react'
import { useThree, useLoader } from "@react-three/fiber";

function Sound({ url, volume, setSoundRefs, soundRefs, index}) {
  const { camera } = useThree()
  const [listener] = useState(() => new THREE.AudioListener())
  const buffer = useLoader(THREE.AudioLoader, url)

  useEffect(()=>{
    // react to volume changes 
    soundRefs.current[index].setVolume(volume);
  },[volume])

  useEffect(() => {
    try{
      soundRefs.current[index].setBuffer(buffer)
      soundRefs.current[index].setRefDistance(.015)
      soundRefs.current[index].setLoop(true)
      camera.add(listener)
    } catch (e) {
      console.log(e)
    }
    return () => camera.remove(listener)
  }, [])

  return <positionalAudio ref={setSoundRefs} args={[listener]} />

}

export default Sound