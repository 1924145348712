import React from 'react'
import styled from 'styled-components';
import MinimapImage from './MinimapImage.js'
import Player from './Player2.js';

const Wrapper = styled.div`
  position:absolute;
  z-index:1;
  width:500px;
`;

const PlayerWrapper = styled.div`
  width:52px;
  position:absolute;
  top:42.5%;
  left:36.5%;
`;

function Minimap({position, letters}) {
  const scale = 7.2;
  
  return (
    <Wrapper>
      <PlayerWrapper>
        <Player style={{ transform: `translateY(${position?.z*scale}px) translateX(${position?.x*scale}px)  rotate(${position?.rotation}rad)`}}/>
      </PlayerWrapper>
      <MinimapImage letters={letters}/>
    </Wrapper>
  )
}

export default Minimap