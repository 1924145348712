import React from 'react'
import styled from 'styled-components';
import ProgressBar from './ProgressBar';
import animationGif from './animation.gif'

const Wrapper = styled.div`
  width: 400px;
  max-height: 450px;
  background:white;
  box-shadow: 0px 10px 36px #00000029;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:space-around;
`;

const Text = styled.p`
  width:90%;
  text-align: center;
  font: 400 16px/26px "Avenir Next Regular";
`;

const Graphic = styled.img`
  height:22vh;
  max-width:200px;
  max-height:220px;
`


function Tutorial({progress, close, soundRefs}) {
  
  return (
    <Wrapper>
      <Graphic src={animationGif}></Graphic>
      <Text>
        Sobald die Welt geladen ist: Drücke die linke Maustaste, um Dich in Bewegung zu setzen. Bewege die Maus, um die Richtung zu bestimmen.
      </Text>
      <ProgressBar progress={progress} close={close} soundRefs={soundRefs}/>
    </Wrapper>
  )
}

export default Tutorial