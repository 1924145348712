import React, {useEffect, useState} from 'react'
import styled from 'styled-components';


const ProgressWrapper = styled.div`
  background:white;
  height:22px;
  z-index:9999;
`

const ProgressIndicator= styled.div`
  height:2px;
  background-color: #E4E4E4;
  width: 100%;
  z-index:10;
  position:relative;
  top:10px;
  &:before{
    content:'';
    position:absolute;
    width:${props => props.completion*100}%;
    height:6px;
    top:-2px;
    background-color: #000;
    transition: width 0.5s ease-in-out;
  }
`

function ProgressBar({playerRef}) {
  const [currentTime, setCurrentTime] = useState(playerRef.current.currentTime);
  
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(playerRef.current.currentTime);
    }, 500);
    return () => clearInterval(interval);
  }, []);


  return (
    <ProgressWrapper>
      <ProgressIndicator completion={currentTime/playerRef?.current?.duration}/>
    </ProgressWrapper>
  )
}

export default ProgressBar