import React, { useRef } from "react";
import { useGLTF, Instances, Instance } from "@react-three/drei";
import waveData from "./stoneData.json"
import * as THREE from 'three'

const setQuaterantion = (item) => {
  const a = new THREE.Quaternion(item.rotation[0],item.rotation[1],item.rotation[2],item.rotation[3])
  return a
}


function Model(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/3D/stein_halftone_einzel.gltf");

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <Instances geometry={nodes.Stein_Particle.geometry} material={materials.SteinTexture}>
          {waveData.map((item, index) =>(
            <group position={item.position} scale={item.scale} quaternion={setQuaterantion(item)} key={`stone-${index}`}>
              <Instance/>
            </group>
          ))}
         </Instances>
      </group>
    </group>
  );
}

useGLTF.preload("/3D/stein_halftone_einzel.gltf");
 
export default Model


    