import * as React from "react"
import styled from 'styled-components'; 

const Text = styled.text`
  font-family:'Avenir Next Heavy';
  font-weight:1000;
  font-size:11px;
  font-style:italic;
  letter-Spacing:.12em;
  fill:white;
`;

const SvgComponent = ({letters}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 580.736 413.865">
    <defs>
      <filter id="a" x={0} y={0} filterUnits="userSpaceOnUse">
        <feOffset dy={10} />
        <feGaussianBlur stdDeviation={18} result="blur" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g filter="url(#a)">
      <path
        data-name="Pfad 594"
        d="m101.373 216.101 10.058-14.269 2.3-63.1s19.16-15.892 34.524-28.261 26.931-21.214 26.931-21.214l26.561-2.3 19.863-26.986 23.8-15.965 32.6 15.965 4.28 21.089 35.461 17.088 78.274 60.159-23.724 66.868 2.967 44.5 23.462 21.565-46.358 39.871-29.342-23.641h-27.684l-30.726-16.234-8.224 27.878-23.174 30.747-35.5-12.494-4.752-29.9-17.786-2.253-33.791 11.309-20.255-18.1-41.024 4.569L54 286.174l6.2-40.939Z"
        fill="#fff"
      />
    </g>
    <path
      data-name="Pfad 608"
      d="M266.306 248.182c6.947 4.013 15.828-2.542 26.011 1.593 13.78 5.6 11.5 10.768 20.033 17.713s14.678 8.575 14.678 8.575"
      fill="none"
      stroke="#000"
      strokeWidth={2}
      strokeDasharray="2 4"
      opacity={0.4}
    />
    <g
      data-name="Ellipse 7"
      transform="translate(286.29 150.44)"
      fill="none"
      stroke="#000"
      strokeWidth={2}
      strokeDasharray="2 4"
      opacity={0.4}
    >
      <ellipse cx={27} cy={26.5} rx={27} ry={26.5} stroke="none" />
      <ellipse cx={27} cy={26.5} rx={26} ry={25.5} />
    </g>
    <g
      data-name="Ellipse 9"
      transform="translate(324.29 262.44)"
      fill="none"
      stroke="#000"
      strokeWidth={2}
      strokeDasharray="2 4"
      opacity={0.4}
    >
      <ellipse cx={27} cy={26.5} rx={27} ry={26.5} stroke="none" />
      <ellipse cx={27} cy={26.5} rx={26} ry={25.5} />
    </g>
    <path
      data-name="Pfad 591"
      d="M274.083 98.549s9.054 2.814 22.811 10.295 19.858 12.062 35.286 22.12 33.468 11.958 38.143 33.063-2.924 19.433-11.338 43.75-1.953 53.656-1.953 53.656"
      fill="none"
      stroke="#000"
      strokeWidth={2}
      strokeDasharray="2 4"
      opacity={0.4}
    />
    <path
      data-name="Pfad 592"
      d="M322.087 290.898s-2.868-1.279-9.994-1-11.435 2.544-17.968 1.4c-15.194-2.669-21.019-13.39-31.956-15.974s-21.818 5.2-21.818 5.2"
      fill="none"
      stroke="#000"
      strokeWidth={2}
      strokeDasharray="2 4"
      opacity={0.4}
    />
    <path
      data-name="Pfad 596"
      d="M123.185 247.968s6.482-.893 20.847 2.031 17.578 7.572 37.718 10.1 25.859 4.493 45.32.872 19.743-2.228 34.343-13.855 13.246-21.744 21.582-33.523 11.983-15.385 11.983-15.385"
      fill="none"
      stroke="#000"
      strokeWidth={2}
      strokeDasharray="2 4"
      opacity={0.4}
    />
    <path
      data-name="Pfad 597"
      d="M228.637 221.846s14.566 17.7 32.84 22.409"
      fill="none"
      stroke="#000"
      strokeWidth={2}
      strokeDasharray="2 4"
      opacity={0.4}
    />
    <path
      data-name="Pfad 598"
      d="M236.688 193.869c2.806-3.384 4.214-6.749 7.6-9.836s4.667-4.173 10.3-6.279 4.98-3.628 12.378-4.1 21.35 0 21.35 0"
      fill="none"
      stroke="#000"
      strokeWidth={2}
      strokeDasharray="2 4"
      opacity={0.4}
    />
    <path
      data-name="Pfad 599"
      d="M259.402 115.231s.82 3.294 2.373 8.049 3.63 2.686 4.839 8.969-2.86 10.27 0 16.162 4.794 4.767 11.439 7.406 15.143 3.148 15.143 3.148"
      fill="none"
      stroke="#000"
      strokeWidth={2}
      strokeDasharray="2 4"
      opacity={0.4}
    />
    <path
      data-name="Pfad 600"
      d="M306.079 204.839s-1.37 10.084 1.135 18.559 3.477 7.9 10.022 15.343 10.529 8.061 16.158 14.435 6.361 11.06 6.361 11.06"
      fill="none"
      stroke="#000"
      strokeWidth={2}
      strokeDasharray="2 4"
      opacity={0.4}
    />
    <g
      data-name="Ellipse 6"
      transform="translate(71.29 235.537)"
      fill="none"
      stroke="#000"
      strokeWidth={2}
      strokeDasharray="2 4"
      opacity={0.4}
    >
      <ellipse cx={27} cy={26.5} rx={27} ry={26.5} stroke="none" />
      <ellipse cx={27} cy={26.5} rx={26} ry={25.5} />
    </g>
    <g data-name="Gruppe 488" opacity={0.4}>
      <path
        data-name="Pfad 590"
        d="M112.216 235.347a152.764 152.764 0 0 1 6.78-14.755c4.427-8.528 10.514-9.173 12.826-19.933s-6.851-38.073-.223-52.87 15.1-12.089 28.373-22.889 11.633-13.621 20.518-17.774 15.449-1.579 25.747-3.155 14.635-4.333 14.635-4.333"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeWidth={2}
        strokeDasharray="2 4"
      />
    </g>
    <path
      data-name="Pfad 593"
      d="M124.463 274.832s3.947 1.113 10.894 6.87 6.238 12.9 15.571 16.085 16.824-7.4 29.4-9.883 20.246 3.05 20.246 3.05"
      fill="none"
      stroke="#000"
      strokeWidth={2}
      strokeDasharray="2 4"
      opacity={0.4}
    />
    <path
      data-name="Pfad 595"
      d="M215.073 267.138c3.998 6.412 3.619 10.004 3.619 10.004"
      fill="none"
      stroke="#000"
      strokeWidth={2}
      strokeDasharray="2 4"
      opacity={0.4}
    />
    <circle
      data-name="Brotkrume"
      cx={4}
      cy={4}
      r={4}
      fill={letters.find(letter => letter.id === "indigo").visited ? "#969696" : "#ffba00"}
      transform="translate(132.29 135.44)"
    />
    <g data-name="Brotkrume" transform="translate(175.29 103.44)">
      <circle data-name="Ellipse 11" cx={4} cy={4} r={4} fill={letters.find(letter => letter.id === "cupido").visited ? "#969696" : "#ffba00"} />
    </g> 
    <g
      data-name="Ellipse 4"
      transform="translate(220.29 61.44)"
      fill="none"
      stroke="#000"
      strokeWidth={2}
      strokeDasharray="2 4"
      opacity={0.4}
    >
      <ellipse cx={27} cy={26.5} rx={27} ry={26.5} stroke="none" />
      <ellipse cx={27} cy={26.5} rx={26} ry={25.5} />
    </g>
    <g
      data-name="Ellipse 5"
      transform="translate(182.29 175.44)"
      fill="none"
      stroke="#000"
      strokeWidth={2}
      strokeDasharray="2 4"
      opacity={0.4}
    >
      <ellipse cx={27} cy={26.5} rx={27} ry={26.5} stroke="none" />
      <ellipse cx={27} cy={26.5} rx={26} ry={25.5} />
    </g>
    <g
      data-name="Ellipse 8"
      transform="translate(198.29 277.44)"
      fill="none"
      stroke="#000"
      strokeWidth={2}
      strokeDasharray="2 4"
      opacity={0.4}
    >
      <ellipse cx={27} cy={26.5} rx={27} ry={26.5} stroke="none" />
      <ellipse cx={27} cy={26.5} rx={26} ry={25.5} />
    </g>
    <g data-name="Brotkrume" transform="translate(128.29 188.043)">
      <circle data-name="Ellipse 11" cx={4} cy={4} r={4} fill={letters.find(letter => letter.id === "laus").visited ? "#969696" : "#ffba00"} />
    </g>
    <g data-name="Brotkrume" transform="translate(344.29 136.098)">
      <circle data-name="Ellipse 11" cx={4} cy={4} r={4} fill={letters.find(letter => letter.id === "big-business").visited ? "#969696" : "#ffba00"} />
    </g>
    <g data-name="Brotkrume" transform="translate(352.29 212.44)">
      <circle data-name="Ellipse 11" cx={4} cy={4} r={4} fill={letters.find(letter => letter.id === "present").visited ? "#969696" : "#ffba00"} />
    </g>
    <g data-name="Brotkrume" transform="translate(321.29 242.44)">
      <circle data-name="Ellipse 11" cx={4} cy={4} r={4} fill={letters.find(letter => letter.id === "textillieferanten").visited ? "#969696" : "#ffba00"} />
    </g>
    <g data-name="Brotkrume" transform="translate(303.29 219.44)">
      <circle data-name="Ellipse 11" cx={4} cy={4} r={4} fill={letters.find(letter => letter.id === "spionage").visited ? "#969696" : "#ffba00"} />
    </g>
    <g data-name="Brotkrume" transform="translate(287.29 245.107)"> 
      <circle data-name="Ellipse 11" cx={4} cy={4} r={4} fill={letters.find(letter => letter.id === "morgenrock").visited ? "#969696" : "#ffba00"} />
    </g>
    <g data-name="Brotkrume" transform="translate(282.29 284.94)">
      <circle data-name="Ellipse 11" cx={4} cy={4} r={4} fill={letters.find(letter => letter.id === "all-over-the-world").visited ? "#969696" : "#ffba00"} /> 
    </g>
    <g data-name="Brotkrume" transform="translate(243.349 234.347)">
      <circle data-name="Ellipse 11" cx={4} cy={4} r={4} fill={letters.find(letter => letter.id === "arbeitstag").visited ? "#969696" : "#ffba00"} /> 
    </g>
    <g data-name="Brotkrume" transform="translate(275.1 217.44)">
      <circle data-name="Ellipse 11" cx={4} cy={4} r={4} fill={letters.find(letter => letter.id === "lieferprobleme").visited ? "#969696" : "#ffba00"} />  
    </g>
    <g data-name="Brotkrume" transform="translate(240.29 179.44)">
      <circle data-name="Ellipse 11" cx={4} cy={4} r={4} fill={letters.find(letter => letter.id === "puppe").visited ? "#969696" : "#ffba00"} /> 
    </g>
    <g data-name="Brotkrume" transform="translate(268.061 168.368)">
      <circle data-name="Ellipse 11" cx={4} cy={4} r={4} fill={letters.find(letter => letter.id === "musterbuecher").visited ? "#969696" : "#ffba00"} /> 
    </g>
    <g data-name="Brotkrume" transform="translate(260.061 123.44)">
      <circle data-name="Ellipse 11" cx={4} cy={4} r={4} fill={letters.find(letter => letter.id === "schoenste-rot").visited ? "#969696" : "#ffba00"} /> 
    </g>
    <g data-name="Brotkrume" transform="translate(262.29 146.44)">
      <circle data-name="Ellipse 11" cx={4} cy={4} r={4} fill={letters.find(letter => letter.id === "unternehmer").visited ? "#969696" : "#ffba00"} /> 
    </g>
    <g data-name="Brotkrume" transform="translate(178.79 257.234)">
      <circle data-name="Ellipse 11" cx={3.5} cy={3.5} r={3.5} fill={letters.find(letter => letter.id === "augsburg-curacao").visited ? "#969696" : "#ffba00"} /> 
    </g>
    <g data-name="Brotkrume" transform="translate(135.29 282.44)">
      <circle data-name="Ellipse 11" cx={4} cy={4} r={4} fill={letters.find(letter => letter.id === "sklavenschiffe").visited ? "#969696" : "#ffba00"} />  
    </g>
    <g data-name="Brotkrume" transform="translate(176.29 283.44)">
      <circle data-name="Ellipse 11" cx={4} cy={4} r={4} fill={letters.find(letter => letter.id === "baum-traenen").visited ? "#969696" : "#ffba00"} />
    </g>

    <Text transform="translate(400.736 97.44)">
      <tspan x={0} y={0}>
        {`${letters.filter(letter => letter.visited).length} ABENTEUER ENTDECKT`}
      </tspan>
    </Text>
    <Text data-name="titel" transform="translate(351.29 68.44)">
      <tspan x={0} y={0}>
         {`${letters.filter(letter => !letter.visited).length} ABENTEUER ZU FINDEN`}
      </tspan>
    </Text>
    <g data-name="Brotkrume" transform="translate(329.29 60.44)">
      <circle data-name="Ellipse 11" cx={4} cy={4} r={4} fill="#ffba00" />
    </g>
    <g data-name="Brotkrume" transform="translate(378.29 89.44)">
      <circle data-name="Ellipse 11" cx={4} cy={4} r={4} fill="#969696" />
    </g>
    <path
      data-name="Pfad 607"
      d="M230.859 112.667s-19.335 6.965-30.105 18.993-5.974 17.521-14.213 30.378-12.374 6.916-18.744 21.049-9.558 20.9-6.739 35.484 18.018 22.833 18.018 22.833 21.012 10.018 31.375 18.529"
      fill="none"
      stroke="#000"
      strokeWidth={2}
      strokeDasharray="2 4"
      opacity={0.4}
    />
    <g data-name="Brotkrume" transform="translate(168.29 232.594)">
      <circle data-name="Ellipse 11" cx={4} cy={4} r={4} fill={letters.find(letter => letter.id === "gum-wars").visited ? "#969696" : "#ffba00"} /> 
    </g>
    <g data-name="Brotkrume" transform="translate(189.29 138.098)">
      <circle data-name="Ellipse 11" cx={4} cy={4} r={4} fill={letters.find(letter => letter.id === "sea").visited ? "#969696" : "#ffba00"} />
    </g>
  </svg>
)

export default SvgComponent
