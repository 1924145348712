import './App.css';
import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import Intro from './components/Intro/Intro';
import lettersData from './data/letters.json'
import VideoModal from './components/modals/VideoModal';
import SliderModal from './components/modals/SliderModal';
import AudioModal from './components/modals/AudioModal';
import Minimap from './components/minimap/Minimap';
import { AudioProvider } from './components/hooks/useAudioPlayer'
import CanvasBridge from './components/CanvasBridge';
import Helmet from './components/Helmet';
import InfoBlock from './components/InfoBlock';
import ProjektInfo from './components/modals/ProjektInfo';
import CompletionModal from './components/modals/CompletionModal';
import useArrayRef from "./components/hooks/useSoundRefArray";

// TODO: instances example: https://codesandbox.io/s/grass-shader-5xho4?file=/src/Grass.js
//TODO: instances of objects high number & Shading  https://spectrum.chat/react-three-fiber/general/using-instancedmesh-declaratively~470dbd71-8c1e-49a9-a96d-8d0c588222bf
//TODO: to do have camera position similar too: https://wayfinder.nfb.ca/ isometric 
//TODO: Spread object on vertex provided by bene
//TODO: Open Modal 
//TODO: collioson check https://stackoverflow.com/questions/73451535/how-to-do-simple-collision-detection-in-react-three-fiber
//https://github.com/pmndrs/racing-game


const Wrapper = styled.div`
  height:100vh;
`;

function App() {
  //TODO use instancing for waves https://docs.pmnd.rs/react-three-fiber/advanced/scaling-performance#instancing
  const [ openModal, setOpenModal ] = useState(false)
  const [ showIntro, setShowIntro ] = useState(true)
  const [ playerPostion, setPlayerPosition] = useState({x:0,y:0})
  const [ loadingProgress, setLoadingProgress ] = useState(0)
  const [ userInteracted, setUserInteracted ] = useState(false)
  const [ disableControls, setDisableControls ] = useState(false)
  const [ showProjectInfo, setShowProjectInfo ] = useState(false)
  const [ showCompletionModal, setShowCompletionModal ] = useState(false)
  const completionModalSeen = useRef(false)
  const [ letters, setLetters ] = useState(lettersData)
  const [ soundRefs, setSoundRefs ] = useArrayRef();
 
  useEffect(()=>{
    const alldone = letters.every(letter => letter.visited)
    if(alldone && !completionModalSeen.current && !openModal){
      setShowCompletionModal(true)
      completionModalSeen.current = true
    }
  },[letters, openModal])
  

  const handleClick = (id) => {
    const matchingContent = letters.find(letter => letter.id === id)
    const contentVisitedTemp = {
      ...matchingContent,
      visited:true,
    }
    const lettersWithoutMatch = letters.filter(letter => letter.id !== id)
    setLetters([...lettersWithoutMatch,{...contentVisitedTemp}])
    setOpenModal(matchingContent)
    setDisableControls(true)
  } 

  const handelCloseIntroClick = () => {
    setShowIntro(false)
    setUserInteracted(true)
  }

  const handleCloseModalClick = () => {
    setOpenModal(false)
    setDisableControls(false)
  }

  const handleCloseProjectInfoClick = () => {
    setShowProjectInfo(false)
    setDisableControls(false)
  }

  const handleCloseCompletionModal = () => {
    setShowCompletionModal(false)
    setDisableControls(false)
  }

  return (
    <>
    <Helmet/>
    <AudioProvider>
    {showIntro &&  <Intro loadingProgress={loadingProgress} close={handelCloseIntroClick} soundRefs={soundRefs}/>}
    {showProjectInfo &&  <ProjektInfo show={showProjectInfo} closeModal={handleCloseProjectInfoClick}/>}
    {showCompletionModal &&  <CompletionModal show={showCompletionModal} closeModal={handleCloseCompletionModal}/>}
    <Wrapper>
      {!disableControls && <InfoBlock setShowIntro={setShowIntro} setShowProjectInfo={setShowProjectInfo} setDisableControls={setDisableControls}/>}
      
        <Minimap position={playerPostion} letters={letters}/>
        {openModal !== false && 
          <>
            {openModal.type === "slider" && <SliderModal content={openModal.content} open={openModal !== false} closeModal={handleCloseModalClick}/>}
            {openModal.type === "audio" && <AudioModal content={openModal.content} open={openModal !== false} closeModal={handleCloseModalClick}/>}
            {openModal.type === "video" && <VideoModal content={openModal.content} open={openModal !== false} closeModal={handleCloseModalClick}/>}
          </>
        }
        <CanvasBridge 
          handleClick={handleClick} 
          disableControls={disableControls}
          setDisableControls={setDisableControls}
          setLoadingProgress={setLoadingProgress} 
          letters={letters} 
          openModal={openModal} 
          setOpenModal={setOpenModal} 
          setPlayerPosition={setPlayerPosition} 
          userInteracted={userInteracted}
          soundRefs={soundRefs} 
          setSoundRefs={setSoundRefs}
        />
    </Wrapper>
    </AudioProvider>
    </>
  );
}

export default App;