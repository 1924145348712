/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from "react";
import { useGLTF, useAnimations, Instances, Instance } from "@react-three/drei";
import waveData from "./waveData2.json"
import { useFrame } from '@react-three/fiber'
import * as THREE from 'three'
import useArrayRef from "../../hooks/useWavesRefArray";
import { gsap } from "gsap";


const setQuaterantion = (item) => {
  const a = new THREE.Quaternion(item.rotation[0],item.rotation[1],item.rotation[2],item.rotation[3])
  return a
}

function Model(props) {
  // working example with instances: https://codesandbox.io/s/floating-instanced-shoes-h8o2d?file=/src/App.js
  // rotation quaternation to eula https://stackoverflow.com/questions/50375928/converting-rotation-stored-as-a-quaternion-to-an-euler-for-a-three-js-mesh
  const group = useRef();
  const { nodes, materials } = useGLTF("/3D/wellen_einzel.gltf");

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <Instances
          geometry={nodes.WellePlane.geometry}
          material={materials["Wellentextur.003"]}
        >
        {waveData.map((item, index) =>( 
          <Wave item={item} key={`wave-${index}`} randomFactor={Math.random()}/>
        ))}
        </Instances>
      </group>
    </group>
  );
}

function Wave({item}){
  const ref = useRef()
  const randomFactor = useRef(Math.random())

  useFrame((state) => {
    const amplitute = 0.02 
    const speed = 2 * randomFactor.current
    const elapsedTime = state.clock.getElapsedTime()
    const position = amplitute * Math.sin(speed * elapsedTime);
    ref.current.position.y = position
  })


  return (  
    <group position={item.position} scale={item.scale} quaternion={setQuaterantion(item)} ref={ref}>
      <Instance />
    </group>
  )
}

useGLTF.preload("/3D/wellen_einzel.gltf");
 
export default Model


