import React, { useRef } from "react";
import useArrayRef from "./hooks/useLetterRefArray";
import { useFrame, useThree } from '@react-three/fiber'
import places from '../data/places'

export function Collisions(props) {
  const group = useRef();
  const { setAudioSrc, audioRef, Pause, setSubtitles, openModal } = props
  const [ refs, setRef ] = useArrayRef();
  const { camera } = useThree()


  useFrame(( ) => {
    const collisionRadius = 3.2
    const mesh2Position = camera.position
    // get all distances between camera and collision objects
    const distances = refs.current.map((ref, index)=>{
      const mesh1Position = ref?.position
      const distance = mesh1Position.distanceTo(mesh2Position)
      return distance
    })

    const minDistance = Math.min(...distances) // get the smallest distance
    const minIndex = distances.indexOf(minDistance) // get the index of the smallest distance
    const currentlyPlayingFileName = audioRef.current.src.split("/")[5]
    const placeAudioFilename = places[minIndex].audioSrc.split("/")[3] // get the audio file that matches the lowest index
  
    if(minDistance < collisionRadius && openModal){
      setSubtitles(undefined) // reset subtitles when audio modal is clicked from inside collision
      if(openModal.type === "video"){
        setAudioSrc('',undefined) // when open modal is video also reset audio src so only the video audio plays
      }
      return 
    } 
      
    if(minDistance < collisionRadius && currentlyPlayingFileName !== placeAudioFilename){
      setAudioSrc(places[minIndex].audioSrc, places[minIndex].trackSrc)
    }
    if(minDistance > collisionRadius && currentlyPlayingFileName === placeAudioFilename){
      setAudioSrc('',undefined)
      setSubtitles(undefined)
      Pause()
    }
  })

  return (
    <group ref={group} {...props} dispose={null}>
      
      <group name="Scene">
        <group
          name="Collision_Amsterdam"
          position={[0.14, 0.01, -13.2]}
          scale={3.2}
          ref={setRef}
        />
        <group
          name="Collision_Augsbrug"
          position={[7.67, 0.01, -3.14]}
          scale={3.2}
          ref={setRef}
        />
        <group
          name="Collision_Curacao"
          position={[-16.71, 0.01, 6.28]}
          scale={3.2}
          ref={setRef}
        />
        <group
          name="Collision_Indien"
          position={[12.15, 0.01, 9.61]}
          scale={3.2}
          ref={setRef}
        />
        <group
          name="Collision_Paris"
          position={[-4.36, 0.01, -0.44]}
          scale={3.2}
          ref={setRef}
        />
        <group
          name="Collision_Westafrika"
          position={[-2.44, 0.01, 11.22]}
          scale={3.2}
          ref={setRef}
        />
      </group>
    </group>
  );
}
export default Collisions



