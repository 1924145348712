

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";
import { useGLTF, Instances, Instance } from "@react-three/drei";
import treeData from "./treesData.json"
import * as THREE from 'three'

const setQuaterantion = (item) => {
  const a = new THREE.Quaternion(item.rotation[0],item.rotation[1],item.rotation[2],item.rotation[3])
  return a
}


function Model(props) {
  // working example with instances: https://codesandbox.io/s/floating-instanced-shoes-h8o2d?file=/src/App.js
  const { nodes, materials } = useGLTF("/3D/baum_halftone2_einzel.gltf");
  return (
    <group {...props} dispose={null}>
      <group name="Scene">
        <Instances geometry={nodes.BaumAbstract217.geometry} material={materials["BaumTexturpack.004"]} >
          {treeData.map((item, index) =>(
            <group position={item.position} scale={item.scale} quaternion={setQuaterantion(item)} key={`tree-${index}`}>
              <Instance/>
            </group>
          ))}
        </Instances>
      </group>
    </group>
  );
}


useGLTF.preload("/3D/baum_halftone2_einzel.gltf");

 
export default Model