import React, {useEffect} from 'react'
import { Html, useProgress } from '@react-three/drei'

function Loader({setLoadingProgress}) {
  const { progress } = useProgress()
  const allstuff = useProgress()

  useEffect(()=>{
    setLoadingProgress(progress)
  },[allstuff])

  return <></>
}

export default Loader