import React from "react";
import { useGLTF } from "@react-three/drei";

function Scene(props) {
  const { nodes, materials } = useGLTF("/3D/welt_inseln+pfade.gltf");
  return (
    <group {...props} dispose={null}>
      <group
        position={[12.84, 0.26, 7.03]}
        rotation={[Math.PI / 2, 0, 2.2]}
        scale={[1, 1.3, 1]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text006.geometry}
          material={materials["Weiß.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text006_1.geometry}
          material={materials["Textfarbe Indien.001"]}
        />
      </group>
      <group
        position={[12.84, 0.26, 7.03]}
        rotation={[Math.PI / 2, 0, 2.2]}
        scale={[1, 1.3, 1]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text008.geometry}
          material={materials["Weiß.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text008_1.geometry}
          material={materials["Textfarbe Indien.001"]}
        />
      </group>
      <group position={[-1.91, 0.26, -13.1]} rotation={[Math.PI / 2, 0, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text009.geometry}
          material={materials["Weiß.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text009_1.geometry}
          material={materials["Textfarbe Amsterdam.001"]}
        />
      </group>
      <group position={[-1.91, 0.26, -13.1]} rotation={[Math.PI / 2, 0, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text019.geometry}
          material={materials["Weiß.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text019_1.geometry}
          material={materials["Textfarbe Amsterdam.001"]}
        />
      </group>
      <group position={[-1.91, 0.26, -13.1]} rotation={[Math.PI / 2, 0, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text020.geometry}
          material={materials["Weiß.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text020_1.geometry}
          material={materials["Textfarbe Amsterdam.001"]}
        />
      </group>
      <group
        position={[7.25, 0.29, -5]}
        rotation={[Math.PI / 2, 0, 1.27]}
        scale={[0.8, 1.3, 0.8]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text027.geometry}
          material={materials["Weiß.006"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text027_1.geometry}
          material={materials["Textfarbe Augsburg.001"]}
        />
      </group>
      <group
        position={[7.45, 0.29, -5.06]}
        rotation={[Math.PI / 2, 0, 1.27]}
        scale={[1, 1.3, 1]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text028.geometry}
          material={materials["Weiß.006"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text028_1.geometry}
          material={materials["Textfarbe Augsburg.001"]}
        />
      </group>
      <group
        position={[-4.1, 0.26, 2.37]}
        rotation={[Math.PI / 2, 0, -1.59]}
        scale={[1.3, 1.4, 1.3]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text022.geometry}
          material={materials["Weiß.003"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text022_1.geometry}
          material={materials["Textfarbe Paris.001"]}
        />
      </group>
      <group
        position={[-4.1, 0.26, 2.37]}
        rotation={[Math.PI / 2, 0, -1.59]}
        scale={[1.3, 1.4, 1.3]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text021.geometry}
          material={materials["Weiß.003"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text021_1.geometry}
          material={materials["Textfarbe Paris.001"]}
        />
      </group>
      <group
        position={[-16.19, 0.15, 8.17]}
        rotation={[Math.PI / 2, 0, -1.94]}
        scale={[1.32, 1.4, 1.32]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text024.geometry}
          material={materials["Weiß.004"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text024_1.geometry}
          material={materials["Textfarbe Curacao .001"]}
        />
      </group>
      <group
        position={[-16.19, 0.15, 8.17]}
        rotation={[Math.PI / 2, 0, -1.94]}
        scale={[1, 1.4, 1]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text023.geometry}
          material={materials["Weiß.004"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text023_1.geometry}
          material={materials["Textfarbe Curacao .001"]}
        />
      </group>
      <group
        position={[1.25, 0.49, 10.14]}
        rotation={[Math.PI / 2, 0, Math.PI]}
        scale={[1, 1.6, 1]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text026.geometry}
          material={materials["Weiß.005"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text026_1.geometry}
          material={materials["Textfarbe Westafrika.001"]}
        />
      </group>
      <group
        position={[0.67, -0.14, 10.14]}
        rotation={[Math.PI / 2, 0, Math.PI]}
        scale={[1.1, 1.6, 1.1]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text025.geometry}
          material={materials["Weiß.005"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text025_1.geometry}
          material={materials["Textfarbe Westafrika.001"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Halftone_Path017.geometry}
        material={materials["LinieHalftone.001"]}
        scale={0.1}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Halftone_Path018.geometry}
        material={materials["LinieHalftone.001"]}
        scale={0.1}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Halftone_Path019.geometry}
        material={materials["LinieHalftone.001"]}
        scale={0.1}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Halftone_Path020.geometry}
        material={materials["LinieHalftone.001"]}
        scale={0.1}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Halftone_Path021.geometry}
        material={materials["LinieHalftone.001"]}
        scale={0.1}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Halftone_Path022.geometry}
        material={materials["LinieHalftone.001"]}
        scale={0.1}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Halftone_Path023.geometry}
        material={materials["LinieHalftone.001"]}
        position={[-0.58, 0.23, 5.33]}
        scale={0.1}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Halftone_Path024.geometry}
        material={materials["LinieHalftone.001"]}
        position={[-0.63, 0.23, 5.33]}
        scale={0.1}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Halftone_Path025.geometry}
        material={materials["LinieHalftone.001"]}
        position={[-0.58, 0.23, 5.33]}
        scale={0.1}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Halftone_Path026.geometry}
        material={materials["LinieHalftone.001"]}
        position={[-0.58, 0.23, 5.33]}
        scale={0.1}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Halftone_Path027.geometry}
        material={materials["LinieHalftone.001"]}
        position={[-0.63, 0.23, 5.33]}
        scale={0.1}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Halftone_Path028.geometry}
        material={materials["LinieHalftone.001"]}
        position={[-0.63, 0.23, 5.33]}
        scale={0.1}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Halftone_Path029.geometry}
        material={materials["LinieHalftone.001"]}
        position={[-0.58, 0.23, 5.33]}
        scale={0.1}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Halftone_Path030.geometry}
        material={materials["LinieHalftone.001"]}
        position={[-0.58, 0.23, 5.33]}
        scale={0.1}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Halftone_Path031.geometry}
        material={materials["LinieHalftone.001"]}
        position={[-0.58, 0.23, 5.33]}
        scale={0.1}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Halftone_Path032.geometry}
        material={materials["LinieHalftone.001"]}
        position={[-0.63, 0.23, 5.33]}
        scale={0.1}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve005.geometry}
        material={materials["Collage Indien.011"]}
        position={[12.84, 0.26, 7.03]}
        rotation={[Math.PI / 2, 0, 2.2]}
        scale={[12.14, 15.78, 12.14]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve006.geometry}
        material={materials["Collage Indien.001"]}
        position={[12.84, 0.26, 7.03]}
        rotation={[Math.PI / 2, 0, 2.2]}
        scale={[12.14, 15.78, 12.14]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve007.geometry}
        material={materials["Collage Indien.002"]}
        position={[12.84, 0.26, 7.03]}
        rotation={[Math.PI / 2, 0, 2.2]}
        scale={[12.14, 15.78, 12.14]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve009.geometry}
        material={materials["Collage Indien.004"]}
        position={[12.84, 0.26, 7.03]}
        rotation={[Math.PI / 2, 0, 2.2]}
        scale={[12.14, 15.78, 12.14]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve010.geometry}
        material={materials["Collage Indien.010"]}
        position={[12.84, 0.26, 7.03]}
        rotation={[Math.PI / 2, 0, 2.2]}
        scale={[12.14, 15.78, 12.14]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve011.geometry}
        material={materials["Collage Indien.005"]}
        position={[12.84, 0.26, 7.03]}
        rotation={[Math.PI / 2, 0, 2.2]}
        scale={[12.14, 15.78, 12.14]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve012.geometry}
        material={materials["Collage Indien.007"]}
        position={[12.84, 0.26, 7.03]}
        rotation={[Math.PI / 2, 0, 2.2]}
        scale={[12.14, 15.78, 12.14]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve013.geometry}
        material={materials["Collage Indien.009"]}
        position={[12.84, 0.26, 7.03]}
        rotation={[Math.PI / 2, 0, 2.2]}
        scale={[12.14, 15.78, 12.14]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve014.geometry}
        material={materials["Collage Indien.008"]}
        position={[12.84, 0.26, 7.03]}
        rotation={[Math.PI / 2, 0, 2.2]}
        scale={[12.14, 15.78, 12.14]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve062.geometry}
        material={materials["Ausgburg Collage.008"]}
        position={[12.84, 0.26, 7.03]}
        rotation={[Math.PI / 2, 0, 2.2]}
        scale={[12.14, 15.78, 12.14]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve033.geometry}
        material={materials["Ausgburg Collage.008"]}
        position={[7.45, 0.29, -5.06]}
        rotation={[Math.PI / 2, 0, 1.27]}
        scale={[10.7, 17.12, 10.7]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve016.geometry}
        material={materials["Amsterdam.009"]}
        position={[-1.91, 0.26, -13.1]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={15}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve017.geometry}
        material={materials.Amsterdam}
        position={[-1.91, 0.26, -13.1]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={15}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve018.geometry}
        material={materials["Amsterdam.012"]}
        position={[-1.91, 0.26, -13.1]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={15}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve019.geometry}
        material={materials["Amsterdam.003"]}
        position={[-1.91, 0.26, -13.1]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={15}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve020.geometry}
        material={materials["Amsterdam.001"]}
        position={[-1.91, 0.26, -13.1]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={15}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve021.geometry}
        material={materials["Amsterdam.005"]}
        position={[-1.91, 0.26, -13.1]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={15}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve022.geometry}
        material={materials["Amsterdam.014"]}
        position={[-1.91, 0.26, -13.1]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={15}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve023.geometry}
        material={materials["Amsterdam.023"]}
        position={[-1.91, 0.26, -13.1]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={15}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve024.geometry}
        material={materials["Amsterdam.010"]}
        position={[-1.91, 0.26, -13.1]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={15}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve025.geometry}
        material={materials["Amsterdam.013"]}
        position={[-1.91, 0.26, -13.1]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={15}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Amsterdam_Collage019.geometry}
        material={materials["Amsterdam.011"]}
        position={[-1.91, 0.26, -13.1]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={15}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve026.geometry}
        material={materials["Ausgburg Collage.006"]}
        position={[7.45, 0.29, -5.06]}
        rotation={[Math.PI / 2, 0, 1.27]}
        scale={[10.7, 17.12, 10.7]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve027.geometry}
        material={materials["Ausgburg Collage.002"]}
        position={[7.45, 0.29, -5.06]}
        rotation={[Math.PI / 2, 0, 1.27]}
        scale={[10.7, 17.12, 10.7]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve028.geometry}
        material={materials["Ausgburg Collage.003"]}
        position={[7.45, 0.29, -5.06]}
        rotation={[Math.PI / 2, 0, 1.27]}
        scale={[10.7, 17.12, 10.7]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve029.geometry}
        material={materials["Ausgburg Collage.004"]}
        position={[7.45, 0.29, -5.06]}
        rotation={[Math.PI / 2, 0, 1.27]}
        scale={[10.7, 17.12, 10.7]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve030.geometry}
        material={materials["Ausgburg Collage.005"]}
        position={[7.45, 0.29, -5.06]}
        rotation={[Math.PI / 2, 0, 1.27]}
        scale={[10.7, 17.12, 10.7]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve031.geometry}
        material={materials["Ausgburg Collage.001"]}
        position={[7.45, 0.29, -5.06]}
        rotation={[Math.PI / 2, 0, 1.27]}
        scale={[10.7, 17.12, 10.7]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve032.geometry}
        material={materials["Ausgburg Collage.007"]}
        position={[7.45, 0.29, -5.06]}
        rotation={[Math.PI / 2, 0, 1.27]}
        scale={[10.7, 17.12, 10.7]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve034.geometry}
        material={materials["Collage Paris.001"]}
        position={[-4.09, 0.26, 2.37]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={[10.71, 15, 10.71]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve035.geometry}
        material={materials["Collage Paris"]}
        position={[-4.09, 0.26, 2.37]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={[10.71, 15, 10.71]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve036.geometry}
        material={materials["Collage Paris.004"]}
        position={[-4.09, 0.26, 2.37]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={[10.71, 15, 10.71]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve037.geometry}
        material={materials["Collage Paris.007"]}
        position={[-4.09, 0.26, 2.37]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={[10.71, 15, 10.71]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve038.geometry}
        material={materials["Collage Paris.003"]}
        position={[-4.09, 0.26, 2.37]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={[10.71, 15, 10.71]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve039.geometry}
        material={materials["Collage Paris.005"]}
        position={[-4.09, 0.26, 2.37]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={[10.71, 15, 10.71]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve040.geometry}
        material={materials["Collage Paris.002"]}
        position={[-4.09, 0.26, 2.37]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={[10.71, 15, 10.71]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve041.geometry}
        material={materials["Collage Paris.006"]}
        position={[-4.09, 0.26, 2.37]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={[10.71, 15, 10.71]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve042.geometry}
        material={materials["Collage Curacao"]}
        position={[-16.19, 0.15, 8.17]}
        rotation={[Math.PI / 2, 0, -1.93]}
        scale={[11.64, 16.29, 11.64]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve043.geometry}
        material={materials["Collage Curacao.001"]}
        position={[-16.19, 0.15, 8.17]}
        rotation={[Math.PI / 2, 0, -1.93]}
        scale={[11.64, 16.29, 11.64]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve044.geometry}
        material={materials["Collage Curacao.002"]}
        position={[-16.19, 0.15, 8.17]}
        rotation={[Math.PI / 2, 0, -1.93]}
        scale={[11.64, 16.29, 11.64]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve045.geometry}
        material={materials["Collage Curacao.003"]}
        position={[-16.19, 0.15, 8.17]}
        rotation={[Math.PI / 2, 0, -1.93]}
        scale={[11.64, 16.29, 11.64]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve046.geometry}
        material={materials["Collage Curacao.004"]}
        position={[-16.19, 0.15, 8.17]}
        rotation={[Math.PI / 2, 0, -1.93]}
        scale={[11.64, 16.29, 11.64]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve047.geometry}
        material={materials["Collage Curacao.005"]}
        position={[-16.19, 0.15, 8.17]}
        rotation={[Math.PI / 2, 0, -1.93]}
        scale={[11.64, 16.29, 11.64]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve048.geometry}
        material={materials["Collage Curacao.006"]}
        position={[-16.19, 0.15, 8.17]}
        rotation={[Math.PI / 2, 0, -1.93]}
        scale={[11.64, 16.29, 11.64]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve057.geometry}
        material={materials["Collage Curacao.008"]}
        position={[-16.19, 0.15, 8.17]}
        rotation={[Math.PI / 2, 0, -1.93]}
        scale={[11.64, 16.29, 11.64]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve050.geometry}
        material={materials["WAfr Collage.002"]}
        position={[-0.09, 0.26, 10.14]}
        rotation={[Math.PI / 2, 0, Math.PI]}
        scale={[10, 16, 10]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve051.geometry}
        material={materials["WAfr Collage.003"]}
        position={[-0.09, 0.26, 10.14]}
        rotation={[Math.PI / 2, 0, Math.PI]}
        scale={[10, 16, 10]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve052.geometry}
        material={materials["WAfr Collage.006"]}
        position={[-0.09, 0.26, 10.14]}
        rotation={[Math.PI / 2, 0, Math.PI]}
        scale={[10, 16, 10]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve059.geometry}
        material={materials["WAfr Collage.006"]}
        position={[-0.09, 0.26, 10.14]}
        rotation={[Math.PI / 2, 0, Math.PI]}
        scale={[10, 16, 10]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve053.geometry}
        material={materials["WAfr Collage.004"]}
        position={[-0.09, 0.26, 10.14]}
        rotation={[Math.PI / 2, 0, Math.PI]}
        scale={[10, 16, 10]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve055.geometry}
        material={materials["WAfr Collage.008"]}
        position={[-0.09, 0.26, 10.14]}
        rotation={[Math.PI / 2, 0, Math.PI]}
        scale={[10, 16, 10]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve056.geometry}
        material={materials["WAfr Collage.008"]}
        position={[-0.09, 0.26, 10.14]}
        rotation={[Math.PI / 2, 0, Math.PI]}
        scale={[10, 16, 10]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve058.geometry}
        material={materials["WAfr Collage.010"]}
        position={[-0.09, 0.26, 10.14]}
        rotation={[Math.PI / 2, 0, Math.PI]}
        scale={[10, 16, 10]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve060.geometry}
        material={materials["WAfr Collage.012"]}
        position={[-0.09, 0.26, 10.14]}
        rotation={[Math.PI / 2, 0, Math.PI]}
        scale={[10, 16, 10]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve049.geometry}
        material={materials["WAfr Collage.001"]}
        position={[-0.09, 0.26, 10.14]}
        rotation={[Math.PI / 2, 0, -Math.PI]}
        scale={10}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve054.geometry}
        material={materials["WAfr Collage.005"]}
        position={[-0.09, 0.26, 10.14]}
        rotation={[Math.PI / 2, 0, -Math.PI]}
        scale={10}
      />
    </group>
  );
}

useGLTF.preload("/3D/welt_inseln+pfade.gltf");

export default Scene