import React, {useEffect} from 'react'
import styled from 'styled-components';
import { Environment, AdaptiveDpr } from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import Loader from './Intro/Loader';
import Collisions from "./Collisions"  
import Scene from './Scene'
import CustomControls from './controls/CustomControls'
import useAudio from './hooks/useAudioPlayer';
import Waves from './3D/Waves/Waves'
import Stones from './3D/Stones/Stones'
import Trees from './3D/Trees/Trees'
import Letters from './3D/Letters'
import Grass from './3D/Grass/index'
/* import { Perf } from 'r3f-perf'; */
import SoundBoundingBoxes from './SoundBoundingBoxes'

const CanvasBg = styled(Canvas)`
  background: rgb(193,185,174);
  background: linear-gradient(0deg, #d8d4d1   35%, #97d8e5 100%);
  // used this: https://cssgradient.io/
`;

function CanvasBridge({handleClick, setLoadingProgress, letters, disableControls, setDisableControls, setPlayerPosition, userInteracted, setOpenModal, setSoundRefs, soundRefs, openModal}) {
  const { setAudioSrc, setSubtitles, audioRef, Pause, Play } = useAudio()




  useEffect(() => {
    window.addEventListener('keydown', handleDeletePress , { passive: false });
    return () => {
      // clean up the event handler when the component unmounts
      window.removeEventListener('keydown', handleDeletePress );
    };
  })

  const handleDeletePress = (e) => {
    const {key} = e
    if(key === 'Escape') { 
      setOpenModal(false)
      setDisableControls(false)
      Pause()
      // in case the suer is inside a schauplatz
      setAudioSrc("",undefined)
      setSubtitles(undefined)
    }
  }

  return (
    <CanvasBg >
      {/* <Perf/> */}
      <AdaptiveDpr pixelated />
      <Grass/>
      <Waves/>
      <Stones/>
      <Trees/> 
      <Environment files="castel_st_angelo_roof_1k.hdr"/>
      <Loader setLoadingProgress={setLoadingProgress}/>
      <Collisions setAudioSrc={setAudioSrc} audioRef={audioRef} Play={Play} Pause={Pause} setSubtitles={setSubtitles} openModal={openModal}/>
      <Scene/>
      <SoundBoundingBoxes userInteracted={userInteracted} setSoundRefs={setSoundRefs} soundRefs={soundRefs}/>
      {userInteracted && 
        <>
          <Letters letters={letters} handleClick={handleClick} />
        </>
      }
      <ambientLight intensity={0.4}/>
      <CustomControls disableControls={disableControls} setDisableControls={setDisableControls} setPlayerPosition={setPlayerPosition} userInteracted={userInteracted}/>
    </CanvasBg>
  )
}

export default CanvasBridge