import { useState, useRef } from 'react'

const useIncrementByTime = (maxValue) =>{
  const [value, setValue] = useState(1);
  const timer = useRef(null); 

  function increment(){
    timer.current = setInterval(() => setValue(prev => prev < maxValue ? prev + 0.30 : prev), 500);
  };

  function clear() {
    clearInterval(timer.current);
    setValue(1)
  }

  return {value, increment, clear}

}

export default useIncrementByTime
