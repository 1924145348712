import { PerspectiveCamera } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import useIncrementByTime from '../hooks/useIncrementByTime'

const angleToRadians = (angleInDeg) => (Math.PI / 180) * angleInDeg;

export default function Three({ disableControls, setPlayerPosition, userInteracted }) {
  //TODO: Framerate independence: https://forum.babylonjs.com/t/fps-affects-game-speed-framerate-independence/2419
  //from: https://www.youtube.com/watch?v=pwOwTXSsfrw
  const camera = useThree((state) => state.camera)
  const camRef = useRef(null)
  const [mousePressed, setMousePressed] = useState(false)
  const [keyPressed, setKeyPressed] = useState(false)
  const maxSpeed = 3.5
  const {value, increment, clear} = useIncrementByTime(maxSpeed)
  const prevTime = useRef('first')


  useEffect(()=>{
    if(userInteracted){
      const listener = new THREE.AudioListener();
      camera.add( listener );
    }

  },[camera, userInteracted])

  const handleKeyDown = (e) => {
    // only do stuff once 
    if(!e.repeat){  
      setKeyPressed(e.key)
      increment()
    }


  }
  const handleKeyUp = (e) =>{
    setKeyPressed(false)
    clear()
  }

  const handlePointerUp = (e) => {
    setMousePressed(false)
    clear()
  }

  const handlePointerDown = (e) => {
    increment()
    setMousePressed(e.which) // set mosue poressed to the specific key so movment only happens on left click
  }

 
  useEffect(() => {
    // check if mouse is pressed and store it into state
    document.addEventListener('mousedown', handlePointerDown, { passive: true });
    document.addEventListener('mouseup', handlePointerUp, { passive: true });
    document.addEventListener('keydown', handleKeyDown, { passive: true });
    document.addEventListener('keyup', handleKeyUp, { passive: true });
   

    return () => {
      // clean up the event handler when the component unmounts
      document.removeEventListener('mousedown', handlePointerDown);
      document.removeEventListener('mouseup', handlePointerUp);
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup',handleKeyUp);
    };
  }, [])
  


    useFrame(( state, delta ) => {
      if (!!camRef.current && !disableControls) {
        const { clock } = state;
        camera.rotation.order = 'YXZ'; // change order so roation around Y doesnt screw up looking up and down
        const { x, y } = state.mouse;

        var direction = new THREE.Vector3();
        camera.getWorldDirection( direction );

        // TODO implment somethign so the user doenst get stuck on the end of the world
        /* const distance  = state.camera.position.distanceTo( new THREE.Vector3() )
        const insideRadius = distance < 22 */
        const insideRadius = true 
        
        // set state less often for performance
        const currTime = clock.getElapsedTime()
        if(currTime - prevTime.current > 0.08 || prevTime.current === 'first'){
          /* console.log(camera) */
          const theta = Math.atan2(direction.x,direction.z);
          
          setPlayerPosition({x:state.camera.position.x, z:state.camera.position.z, rotation:-theta})
          prevTime.current = clock.getElapsedTime()
        }

        if((x > 0.25 || x < -0.25)){    // movethreshold so the camera can be still sometimes
          camera.rotation.set(camera.rotation.x,camera.rotation.y - x * delta * 600  * angleToRadians(0.2) , camera.rotation.z);
        }

        // move up and down all the time
        camera.rotation.set(y * angleToRadians(5)+angleToRadians(11), camera.rotation.y , camera.rotation.z);
      
        
        
         
      
        let directionNoY = new THREE.Vector3(direction.x, 0.00, direction.z) // keep the same height in the movement
        if(mousePressed === 1){
          // move in direction of the click
          let distance = 0.03*value*delta*60;
          insideRadius && camera.position.add( directionNoY.multiplyScalar(distance) );
        }
        
        if(keyPressed === "w"){
          let distance = 0.03*value*delta*60;
          insideRadius && camera.position.add( directionNoY.multiplyScalar(distance) );
        }
        if(keyPressed === "s"){
          let distance = -0.03*value;
          camera.position.add( directionNoY.multiplyScalar(distance) );
        }
        
        const rotationSpeedFactor = 20
        if(keyPressed === "d"){
          camera.rotation.set(camera.rotation.x,camera.rotation.y - rotationSpeedFactor * delta * 5  * angleToRadians(0.2) , camera.rotation.z);
        }
        if(keyPressed === "a"){
          camera.rotation.set(camera.rotation.x,camera.rotation.y + rotationSpeedFactor * delta * 5  * angleToRadians(0.2) , camera.rotation.z);
        }

         if(keyPressed === "i"){
          /* console.log(camera.position)
          console.log(camera.rotation) */
        }
        
      }
    })

    return (
      <PerspectiveCamera  
        ref={camRef} 
        makeDefault 
        position={[-0.52, 0.2, -1.69]} 
        far={80} 
        near={0.1}
        fov={68.04}  
        rotation={[1.15,-0.77,0 ]}
      /> 
    )
}
