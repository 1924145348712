import {useRef} from 'react'

function useArrayRef() {
  const refs = useRef([]);
  const setRef = (ref) => {
    const match = refs.current.filter(item => item?.uid === ref?.uid)
    if(match.length === 0 && ref){
      ref && refs.current.push(ref) 
    }

  }

  return [refs, setRef];
}

export default useArrayRef