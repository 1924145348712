import React, { useRef, useState, useEffect } from 'react';
import ModalWrapper from './ModalWrapper';
import styled from 'styled-components';
import ModalTitle from './ModalTitle';
import Caption from './Caption';
import ProgressBar from '../player/ProgressBar';
import PlayPauseButton from '../player/PlayPauseButton';
import useAudio from '../hooks/useAudioPlayer';

const Image = styled.img`
  max-width:100%;
`;

const ProgressWrapper = styled.div`
  position:absolute;
  bottom:20px;
  width:95%;
  left:50%;
  transform:translateX(-50%);
`

const ImageWrapper = styled.div`
  position:relative;
  display:flex;
  justify-content:center;
  align-items:center;
`;

const AudioModal = ({open, closeModal, content}) => {
  const { audioRef, setAudioSrc, Play, playing, Pause } = useAudio()
  const timeoutRef = useRef()
  const [displayControls, setDisplayControls] = useState(false)

  useEffect(()=> {
    setAudioSrc(content.audioSrc, undefined)
    // dont add setAudioSrc as a dependency otherwise audio cant be paused

    
  },[content])


  const handleCloseClick = () => {
    closeModal()
    setAudioSrc("", undefined)
    Pause()
  }

  const handlePointerMove = () => {
    clearTimeout(timeoutRef.current) 
    setDisplayControls(true)
    timeoutRef.current = setTimeout(() => {
      setDisplayControls(false)
    }, 1000)
  }

  const handleVideoClick = () => {
    handlePointerMove()
    if(playing){
      Pause()
    } else {
      Play()
    }
  }

  const handlePlayPauseClick = () => {
    if(playing){
      console.log("pausing")
      Pause()
    } else {
      console.log("playing")
      Play()
    }
  }



  return (
    <ModalWrapper pb={"50px"} open={open}  closeModal={handleCloseClick}>
      <ModalTitle title={content.title}></ModalTitle>
      <div onPointerMove={handlePointerMove} onClick={handleVideoClick}>
        <ImageWrapper>
          <Image src={content.coverImg}></Image>
          {displayControls &&
            <>
              <ProgressWrapper>
                <ProgressBar playerRef={audioRef}/>
              </ProgressWrapper>
              <PlayPauseButton playing={playing} handleClick={handlePlayPauseClick}/> 
            </>
          }
        </ImageWrapper>
        <Caption>{content.caption}</Caption>
      </div>
    </ModalWrapper>
  )
}


export default AudioModal