import * as React from "react"

const SvgComponent = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    x={0}
    y={0}

    viewBox="0 0 50 50"
  >
    <style>{".st1{fill:#ed2224}"}</style>
    <g id="Klick" transform="rotate(109.01 62.79 155.569)">
      <path
        id="Pfad_602"
        d="M-38.43 239.31c-2.84 5.37-9.5 7.42-14.87 4.58-5.37-2.84-7.42-9.5-4.58-14.87s9.5-7.42 14.87-4.58"
        style={{
          opacity: 0.496,
          fill: "none",
          stroke: "#ed2224",
          strokeWidth: 2.0015,
          strokeMiterlimit: 4.003,
          enableBackground: "new",
        }}
      />
      <g id="Differenzmenge_1">
        <path
          d="M-52.526 231.757a5.01 5.01 0 0 1 6.38-2.17l-4.1 3.84-1.27 1.19 1.66.5 5.9 1.77a5.011 5.011 0 0 1-6.61 1.66 5.007 5.007 0 0 1-1.96-6.79z"
          className="st1"
        />
        <path
          d="M-51.646 232.237c-.28.5-.43 1.04-.48 1.58l3.89-3.65c-1.38.04-2.7.78-3.41 2.07m-.26 3.31c.32.88.95 1.65 1.82 2.13 1.41.78 3.09.61 4.31-.29l-6.13-1.84m-1.5-4.27c1.6-2.9 5.25-3.96 8.15-2.36.26.15.52.31.76.49l-5.07 4.75 7.04 2.12c-.1.27-.23.54-.36.79a6.002 6.002 0 0 1-8.15 2.36c-2.9-1.6-3.96-5.26-2.37-8.15z"
          className="st1"
        />
      </g>
      <path
        id="Pfad_601"
        d="M-32.24 242.58c-4.65 8.79-15.54 12.15-24.33 7.5s-12.15-15.54-7.5-24.33 15.54-12.15 24.33-7.5"
        style={{
          opacity: 0.154,
          fill: "none",
          stroke: "#ed2224",
          strokeWidth: 2.0015,
          strokeMiterlimit: 4.003,
          enableBackground: "new",
        }}
      />
    </g>
  </svg>
)

export default SvgComponent
