import React from 'react'
import styled from 'styled-components'
import BgImg from './background.jpg'


const PopupWrapper = styled.div`
  background-color:white;
  padding:20px;
  z-index:10;
  position:absolute;
  height: fit-content;
  top:45%;
  left:50%;
  transform:translate(-50%,-50%);
  max-width: 600px;
  
`

const TopWrapper = styled.div`
  width:100%;
  border-bottom: 2px solid black;
  display: flex;
  justify-content: space-between;
  >h1{
    font-weight:bold;
    font-size:42px;
    font-family: 'Avenir Next Medium';
    text-transform: uppercase;
    margin:0;
    font-size:1.4rem;
  }
`

const Wrapper = styled.div`
  background-color:black;
  top:0px;
  z-index:9999;
  position: fixed;
  height:100vh;
  width: 100vw;
  overflow: hidden;
  background-image:url(${BgImg});
  background-size:cover;
  background-position: 50%;
`

const TextWrapper = styled.div`
  padding:10px 0;
  a{
    color:black;
    font-family: "Avenir Next";
  }
`


const MobileWarning = () => {
  return(
    <Wrapper>
      <PopupWrapper>
        <TopWrapper><h1>STOFF OHNE GRENZEN</h1></TopWrapper>
        <TextWrapper>
          <p>Der Besuch dieser interaktiven Plattform zur Reise durch die Geschichte des Textilhandels im 18. Jahrhundert wird Dir mit einem größeren Bildschirm viel Freude bereiten!</p>
          
          <p>Bitte benutze Deinen Computer und gehe auf: <a href="https://stoffohnegrenzen.timbayern.de" target="_blank">https://stoffohnegrenzen.timbayern.de</a></p>
        </TextWrapper>
      </PopupWrapper>
    </Wrapper>
  )
};

export default MobileWarning